.ScopeInDm {
  background-color:#e6ecf0;
  padding: 50px 0px;
  position: relative;
  z-index: 10;
}
.ScopeInDmTitle h3 {
  color: #0e476b;
  font-size: 35px;
}
.scope-cards-outer{
  padding: 10px !important ;
}
.scopeOfDMPoints {
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
  0 0px 0px rgba(0, 9, 128, 0.05);
  background: #fff;
  height: 270px;
}
.scopeOfDMPoints h5 {
  color: #0e476b;
  font-weight: 700;
  text-align: center;
}
.scopeOfDM-text {
  padding: 10px 25px;
  color: #3c3c3c;
}

.scopOfDm-top-text,
.scopeOfDm-bottom-text {
  color: #3c3c3c;
  padding: 30px 0px;
}

/* 49 */
.overlay-one {
  position: relative;
  transition: all 0.5s ease;
  /* overflow: hidden; */
  cursor: pointer;
  
}
.overlay-one::-webkit-scrollbar {
  display: none;
}

/* 69 */
.overlay-one:before {
  bottom: 0;
  right: -10%;
  background: linear-gradient(270deg, transparent, #79bd12);
  z-index: 1;
}

/* 67 */
.overlay-one .overlay-two:before,
.overlay-one:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  transition: all 0.5s ease;
  opacity: 0;
}

/* 144 */
.overlay-one:hover:before {
  right: 30px;
  opacity: 1;
}

/* 89 */
.overlay-one .overlay-two:before {
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, #79bd12);
}

/* 148 */
.overlay-one:hover .overlay-two:before {
  left: 30px;
  opacity: 1;
}

/* 108 */
.overlay-one .overlay-two:after {
  top: -100%;
  right: 0;
  background: linear-gradient(180deg, transparent, #79bd12);
}

/* 106 */
.overlay-one .overlay-two:after,
.overlay-one:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
}

/* 152 */
.overlay-one:hover .overlay-two:after {
  top: 30px;
  opacity: 1;
}

/* 156 */
.overlay-one:hover:after {
  bottom: 30px;
  opacity: 1;
}
/* 127 */
.overlay-one:after {
  bottom: -100%;
  left: 0;
  background: linear-gradient(0deg, transparent, #79bd12);
}




/* Media Queries */
@media (max-width: 576px) {
.overlay-one:before{
  right: 0;
}
.scopeOfDMPoints{
  height: unset;
}}