.sectionWhyGd {
  position: relative;
  z-index: 10;
}
.whyGdWrapper {
  position: relative;
  display: flex;
}
.whyGdTitle h3 {
  font-size: 35px !important;
  color: #fff;
}
.whyGdTitle p {
  color: #676767;
}

.whyGdImgWrapper {
  width: 30%;
}
.whyGdImg {
  width: 100%;
  /* height: 100vh; */
  height: 700px;
  position: sticky;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.whyGdcontent {
  background: #182503;
  padding: 30px;
  width: 70%;
}
.gdcontent-list .srNo {
  display: flex;
}
.gdcontent-list .srNo .srNoIcon {
  color: #fdc305;
  padding: 5px;
  font-weight: bolder;
  transition: 1s;
}
.gdcontent {
  cursor: pointer;
}

.gdcontent-list li div:hover .srNo .srNoIcon{
  transform: scale(1.3);
}

.gdcontent-list li {
  padding: 10px 0px;
  list-style-type: none;
}
.gdcontent-list h5 {
  color: #fff;
}
.gdcontent-list p {
  color: #fff;
}
.gdHr {
  color: #fff;
}


/* Media Queries */
@media (max-width: 576px) {
  .whyGdImgWrapper{
    display: none;
  }
  .whyGdcontent{
    width: 100%;
  }
  .gdcontent-list{
    padding-left: 0;
  }
}



@media (max-width: 768px) {
.gdcontent-list{
  padding-left: 0;
}
}