.moduleSection {
  padding: 50px 0px;
  background: url("../Images/global.png");
  background-repeat: repeat;
  background-position: right -68px top 35%;
  /* background-color: #010325; */
  background-color: #041520;
  position: relative;
  z-index: 10;
}
.ModulesTitle h3 {
  color: #fff;
  font-size: 35px;
  padding-top: 10px;
  margin-bottom: 115px;
}

.moduleWapper {
  /* height: 250px; */
}
.module {
  position: relative;
  height: 280px;
  padding: 0px 10px;
}
.moduleImage {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  margin-bottom: 0;
  overflow: hidden;
  position: absolute;
  top: -69px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.moduleImage img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 300ms ease;
}
.moduleIcon {
  background-color: #79bd12;
  padding: 10px;
  border-radius: 50%;
  border: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 105px;
  width: 105px;
  position: absolute;
  top: -42px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.moduleIcon img {
  position: absolute;
  left: 50%;
  top: 52%;
  transition: all 300ms ease;
  padding: 10px;
  height: 70px;
  width: 70px;
  transform: translate(-50px, -50px);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 34px;
}
.module:hover .moduleIcon img {
  transform: translate(-50%, -50%) rotate(360deg);
}
.moduleDescription {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}
.moduleDescription p {
  color: #0e476b;
  text-align: center;
  margin-top: 95px;
  padding: 0px 25px;
}
