.eligibilityWrapper {
    padding: 50px 0px;
    background: #E6ECF0;
    position: relative;
    z-index: 10;
  }
  .eligibilitySection {
    margin-right: 20px;
  }
  .eligibilitySection p {
    /* color: #3c3c3c; */
    padding: 10px 0px;
  }
  .eligibilityTitle h3 {
    color: #0e476b;
  }
  .eligibility-hr-line{
    color: #79bd12;
  }
  .eligibilitylist li {
    list-style: none;
    display: flex;
    align-items: center;
  }
  .eligibilitylist p {
    /* color: #3c3c3c; */
    padding-left: 10px;
    
  }
  .row-job-opp{
    display: flex;
    align-items: center;
  }
  .eligibilitylist .checkIcon {
    /* margin-top: 6px; */
    color: #0e476b;
  }
  
  .downloadSyllabusBtn {
    display: flex;
    justify-content: center;
  }
  .downloadSyllabusBtn p{
    color: #3c3c3c;
    font-size: 25px;
  }
  .btnDownloadWrapper {
    display: flex;
    justify-content: center;
  }
  .btnDwn {
    padding: 10px 20px;
    text-transform: uppercase;
    background: #79bd12;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-weight: 700;
    margin: 10px 0px;
  }
  