/* video */
.carousel-item {
  transform: scale(1);
  max-height: 100vh;
  min-height: calc(100vh-180px);
  overflow: hidden;
}
#carouselExampleIndicators {
  position: relative;
  min-height: 100vh;
}
.containerVideobg {
  background-color: var(--second);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -2;
  padding: 0 !important;
  max-height: 100vh;
  overflow: hidden;
}
.videoSliderBackground {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  min-width: 100vh;
  min-height: 100vh;
}
.videoSlider {
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  width: 40%;
  transition: all 0.5s;
  border: 10px solid var(--primary);
  background-color: var(--primary);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
.elVideo {
  width: 100%;
  height: 100%;
}
.active .videoSlider {
  margin-top: 0;
  width: 100%;
  border: none;
  box-shadow: none;
}

.slider-desktop {
  display: block;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.slider-mb {
  display: none;
}

/* CAROUSEL */
.carousel-img {
  height: 80vh;
  position: relative;
  width: 100%;
}
/* .carousel-img::after {
    position: absolute;
    background-color: rgb(0 0 0 0.5);
    opacity: 0.5;
    inset: 0px;
  }  */

.carousel-text1 {
  position: absolute;
  top: 20%;
  left: 100px;
  right: 0;
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.carousel-inner-wrapper1 {
  margin: 0px auto;
  padding: 20px;
  width: 100%;
  display: grid;
  place-content: center;
}

.carousel-text2 {
  position: absolute;
  top: 20%;
  right: -300px;
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  display: flex;
  justify-content: end;
}
.carousel-inner-wrapper2 {
  margin: 0px auto;
  padding: 20px;
  width: 100%;
  display: grid;
  place-content: end;
}

.carousel-text3 {
  position: absolute;
  top: 20%;
  left: 10px;
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  display: flex;
  justify-content: end;
}
.carousel-inner-wrapper3 {
  margin: 0px auto;
  padding: 20px;
  width: 100%;
  display: grid;
  place-content: start;
}

.carousel-inner-wrapper1 h3 {
  /* color: #fff; */
  text-align: center;
  font-weight: 600;
  font-size: 50px;
  width: 70%;
  text-align: center;
  letter-spacing: 0.2px;
  padding-left: 100px;
}
.carousel-inner-wrapper2 h3,
.carousel-inner-wrapper3 h3 {
  /* color: #fff; */
  text-align: end;
  font-weight: 600;
  font-size: 50px;
  width: 70%;
  text-align: start;
  letter-spacing: 0.2px;
}

.carousel-inner-wrapper1 p {
  /* color: #fff; */
  font-size: 20px;
  width: 80%;
  text-align: center;
  opacity: 0.9;
  letter-spacing: 0.4px;
  padding: 0 100px;
}
.carousel-inner-wrapper2 p,
.carousel-inner-wrapper3 p {
  /* color: #fff; */
  font-size: 20px;
  width: 70%;
  text-align: start;
  opacity: 0.9;
  letter-spacing: 0.4px;
}

/*   mission vission */
.mission-vission-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.aboutSection-1-row {
  background: #0e476b;
}
.mission-vission-wrapper-img {
  display: flex;
}
.mission-img {
  width: 100%;
}
.mission-img img {
  width: 400px;
  height: 400px;
}
.mission-vission-row {
  align-items: center;
}
.mission-vission-content {
  padding: 20px 0px;
}
.technology-text-mission,
.technology-text-vission {
  padding: 20px 10px;
}
.technology-text-mission p,
.technology-text-vission p {
  color: #fff;
  font-size: 18px;
}
.technology-text-mission h3,
.technology-text-vission h3 {
  color: #79bd12;
}
/* .mission-vission-wrapper {
  display: flex;
  justify-content: end;
  padding: 50px 0px;
} */
/* .about-slider-Image {
  width: 100%;
  background: linear-gradient(
      to top right,
      rgba(0, 0, 0, 1.5),
      rgba(255, 255, 255, 0.1)
    ),
    url("../Images/Digitalmarketing/aboutSlide.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-info-wrapper p {
  color: #fff;
  padding-left: 70px;
  letter-spacing: 0.5px;
} */
/* section 2 */
.AboutSection-2 {
  background: #f3f3f3;
  background: url("../Images/shape-12.png");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 50px 250px;
  position: relative;
  z-index: 10;
}
.AboutSection-2_bg {
  background: #fff;
  position: relative;
  height: 900px;
  width: 744px;
  margin: 45px auto 0;
}
.AboutSection-2Wrapper {
  display: flex;
  flex-direction: column;
}
.AboutSection-2_imageWrapper {
  /* position: absolute;
  top: -64px;
  left: -135px; */
}
.AboutSection-2_Img img {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-position: 50% 10%;
   position: absolute;
  top: -64px;
  left: -135px;
}
.aboutsectionTextWrapper {
  position: absolute;
  top: 0;
  left: 55%;
  width: 60%;
}

.aboutsectionText h3 {
  color: #0e476b;
  padding-bottom: 10px;
  font-size: 45px;
}

.aboutsectionText p {
  padding: 5px 0px;
}
.aboutusSection-2_cardsBody {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
}
.aboutsectionText {
  padding: 25px 5px;
}
.aboutsectionText a {
  text-decoration: none;
  color: #79bd12;
}
.training,
.consulting,
.placement {
  background: #f1f8e7;
  box-shadow: 5px 5px 20px rgba(14, 71, 107, 0.2);
  border-radius: 5px;
  height: 280px;
  padding: 30px 25px 0px 25px;
}

.training h3,
.consulting h3,
.placement h3 {
  color: #0e476b;
  font-size: 30px;
  border-bottom: 1px solid rgba(24, 37, 3, 0.3);
  padding: 2px;
}

.training p,
.consulting p,
.placement p {
  letter-spacing: 0.3px;
  line-height: 23px;
  padding: 10px 10px;
}

.cardsWrapper {
  padding: 10px 15px;
}

/* section 3 */
.AboutSection-3 {
  background: url("../Images/aboutbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.whatElse-body {
  padding-right: 74.5px;
  position: relative;
}
.whatElseWeDo-right {
  background-color: rgb(0, 0, 0, 0.7);
  /* height: 400px; */
  display: flex;
  align-items: center;
}
.whatElseWeDo-right-content {
  padding: 50px;
}
.whatElseHeading h3,
.whatElseText p {
  color: #fff;
}
.whatElseText p {
  font-size: 18px;
}

.AboutSection-try {
  background: url("../Images/aboutUsSection1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.whatElse-body-try {
  padding-right: 74.5px;
  position: relative;
}
.whatElseWeDo-right-try {
  background-color: rgb(0, 71, 107, 0.7);
  height: 400px;
  display: flex;
  align-items: center;
}
.whatElseWeDo-right-content-try {
  padding: 50px;
}

.whatElseText-try p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.coopTraining {
  background: #79bd12;
  padding: 5px;
  width: fit-content;
}

/* Media Queries */
@media (max-width: 576px) {
  .AboutSection-2_bg {
    width: 335px !important;
    height: 1700px !important;
    margin-top: 180px !important;
  }
  .mission-img img {
    width: 300px;
    height: 300px;
  }
  .technology-text {
    padding: 50px 20px;
  }
  .technology-col-img {
    height: unset !important;
  }
  .AboutSection-2 {
    padding: 20px 0px !important;
  }
  .AboutSection-2_Img {
    display: flex;
    justify-content: center;
  }
  .AboutSection-2_Img img {
    width: 315px;
    height: 315px;
    position: absolute;
    top: -115px !important;
  }
  .aboutsectionTextWrapper {
    position: absolute !important;
    top: 15% !important;
    width: 100% !important;
    left: 0 !important;
    padding: 0 10px !important;
  }
  .AboutSection-2_imageWrapper {
    left: 0 !important;
    right: 0 !important;
  }
  .aboutsectionText h3 {
    font-size: 31px !important;
  }
  .aboutusSection-2_cardsBody {
    top: 62% !important;
  }
  .whatElse-body {
    padding-right: 0;
  }
  .whatElse-body-try {
    padding: 0;
  }
  .whatElseWeDo-right {
    height: auto;
  }
  .whatElseWeDo-right-content,
  .whatElseWeDo-right-content-try {
    padding: 10px !important;
  }
  .training,
  .consulting,
  .placement {
    height: 306px !important;
  }
}

@media (max-width: 768px) {
  .AboutSection-2 {
    padding: 80px 0px !important;
  }
  .AboutSection-2_bg {
    background: none !important;
    position: relative !important;
    height: unset !important;
    width: unset !important;
    margin: unset !important;
  }
  .aboutSection-1-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .technology-text {
    padding: 50px 20px;
  }
  .technology-col-img {
    height: unset !important;
  }
  .AboutSection-2_Img {
    display: flex;
    justify-content: center;
  }
  .AboutSection-2_Img img {
    width: 315px;
    height: 315px;
    /* position: absolute;
    top: -80px; */
    position: initial !important;
  }
  .aboutsectionTextWrapper {
    position: initial !important;
    /* top: 15%;
    width: 100%;
    left: 0 !important;
    padding: 0 10px; */
    width: 100% !important;
  }
  .AboutSection-2_imageWrapper {
    /* left: 0;
    right: 0;
    top: -40px; */
    position: initial !important;
  }
  .aboutsectionText h3 {
    text-align: center;
    font-size: 40px;
  }
  .aboutusSection-2_cardsBody {
    /* top: 50%; */
    position: initial;
  }
  .whatElse-body-try {
    padding: 0;
  }
  .whatElseWeDo-right {
    height: auto;
  }
  .whatElseWeDo-right-content,
  .whatElseWeDo-right-content-try {
    /* padding: 20px; */
  }
  .mission-img img {
    width: 350px !important;
    height: 350px !important;
  }
  .training,
  .consulting,
  .placement {
    height: unset !important;
    padding: 20px 25px 5px 25px;
  }
}

@media (max-width: 1200px) {
  .AboutSection-2_Img img {
    width: 335px;
    height: 335px;
    position: absolute;
    top: -19px;
    left: -15%;
  }
  .AboutSection-2 {
    padding: 50px 150px;
  }
  .aboutsectionTextWrapper {
    left: 40%;
  }
  .training,
  .consulting,
  .placement {
    height: 306px;
    padding: 20px 25px 0px 25px;
  }
  .AboutSection-2_imageWrapper {
    left: -90px;
  }
}
