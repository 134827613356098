.placement-section {
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding: 50px 0px;
}

.placement-col-wrapper {
  padding: 10px;
}
.placement-content {
  background: #fafafa;
  padding: 30px 20px;
  border: 1px solid #f1f1f1;
}
.placement-col-wrapper .profile-photo {
  display: flex;
  justify-content: center;
}
.placement-col-wrapper .profile-photo img {
  width: 100px;
  height: 100px;
}
.placement-col-wrapper .placement-data {
  padding-top: 20px;
}
.placement-col-wrapper .placement-data p {
  font-size: 18px;
  color: #0e476b;
  text-align: center;
}
.placement-col-wrapper .placement-data p:nth-child(2) {
  font-size: 15px;
  color: #79bd12;
}
.placement-heading {
  display: flex;
  justify-content: center;
}
.placement-heading h5{
  color: #3c3c3c;
}
