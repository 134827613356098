.faqs {
  padding: 25px 0;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.faq-outer-wrapper {
  padding: 0 15px;
}

.faq-heading h3 {
  color: #0e476b;
  text-align: center;
}
.faq-heading h5 {
  color: #6e6e6e;
  text-align: center;
}

.faqs-section {
  padding-top: 50px;
}

.faq-img-wrapper {
  display: flex;
  justify-content: center;
}

.faq-img-wrapper img {
  width: 100%;
}

.main-que {
  border-bottom: 0.5px solid #b8b9b9;
  transition: 0.5s ease-in-out;
}

.que-icon {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  transition: 0.5s ease-in-out;
}

.main-que h5 {
  font-size: 19px;
}

.main-que p {
  font-size: 18px;
  color: #0e476b;
  font-weight: 400 !important;
  border-top: 0.5px solid #b8b9b9;
  padding: 10px 0 10px 20px;
}
.sub-menu {
  display: none;
}

.sub-menu.is-open {
  display: block;
}

.faq-minus-plus-icon {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #cecece;
}

/*****************/

.slide-by-one-in-group .carousel-control {
  background-image: none !important;
}

.slide-by-one-in-group .cloneditem-1,
.slide-by-one-in-group .cloneditem-2,
.slide-by-one-in-group .cloneditem-3 {
  display: none;
}

@media (min-width: 768px) {
  .slide-by-one-in-group .carousel-inner > .active.left,
  .slide-by-one-in-group .carousel-inner > .prev {
    left: -50%;
  }
  .slide-by-one-in-group .carousel-inner > .active.right,
  .slide-by-one-in-group .carousel-inner > .next {
    left: 50%;
  }
  .slide-by-one-in-group .carousel-inner > .left,
  .slide-by-one-in-group .carousel-inner > .prev.right,
  .slide-by-one-in-group .carousel-inner > .active {
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner .cloneditem-1 {
    display: block;
  }
}

@media (min-width: 768px) and (transform-3d),
  (min-width: 768px) and (-webkit-transform-3d) {
  .slide-by-one-in-group .carousel-inner > .item.active.right,
  .slide-by-one-in-group .carousel-inner > .item.next {
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.active.left,
  .slide-by-one-in-group .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.left,
  .slide-by-one-in-group .carousel-inner > .item.prev.right,
  .slide-by-one-in-group .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media (min-width: 992px) {
  .slide-by-one-in-group .carousel-inner > .active.left,
  .slide-by-one-in-group .carousel-inner > .prev {
    left: -33.33333333%;
  }
  .slide-by-one-in-group .carousel-inner > .active.right,
  .slide-by-one-in-group .carousel-inner > .next {
    left: 33.33333333%;
  }
  .slide-by-one-in-group .carousel-inner > .left,
  .slide-by-one-in-group .carousel-inner > .prev.right,
  .slide-by-one-in-group .carousel-inner > .active {
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner .cloneditem-2 {
    display: block;
  }
}

@media (min-width: 992px) and (transform-3d),
  (min-width: 992px) and (-webkit-transform-3d) {
  .slide-by-one-in-group .carousel-inner > .item.active.right,
  .slide-by-one-in-group .carousel-inner > .item.next {
    -webkit-transform: translate3d(33.33333333%, 0, 0);
    transform: translate3d(33.33333333%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.active.left,
  .slide-by-one-in-group .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-33.33333333%, 0, 0);
    transform: translate3d(-33.33333333%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.left,
  .slide-by-one-in-group .carousel-inner > .item.prev.right,
  .slide-by-one-in-group .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media (min-width: 1200px) {
  .slide-by-one-in-group .carousel-inner > .active.left,
  .slide-by-one-in-group .carousel-inner > .prev {
    left: -25%;
  }
  .slide-by-one-in-group .carousel-inner > .active.right,
  .slide-by-one-in-group .carousel-inner > .next {
    left: 25%;
  }
  .slide-by-one-in-group .carousel-inner > .left,
  .slide-by-one-in-group .carousel-inner > .prev.right,
  .slide-by-one-in-group .carousel-inner > .active {
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner .cloneditem-3 {
    display: block;
  }
}

@media (min-width: 1200px) and (transform-3d),
  (min-width: 1200px) and (-webkit-transform-3d) {
  .slide-by-one-in-group .carousel-inner > .item.active.right,
  .slide-by-one-in-group .carousel-inner > .item.next {
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.active.left,
  .slide-by-one-in-group .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
    left: 0;
  }
  .slide-by-one-in-group .carousel-inner > .item.left,
  .slide-by-one-in-group .carousel-inner > .item.prev.right,
  .slide-by-one-in-group .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

/* Make the images wide and responsive */
.carousel-inner img {
  height: auto;
  width: 100%;
}

@media (max-width: 768px) {
  .faqs-section-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .main-que h5 {
    font-size: 16px;
  }
  .faq-img-wrapper img{
    /* width: 150px; */
  }
}
