.testimonials {
  padding: 50px 0px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.test-heading h3,
.test-heading h5 {
  color: #0e476b;
  text-align: center;
}

/* .testimonial-section {
  padding-top: 70px;
} */

.testimonial-outer-wrapper {
  padding: 0px 10px;
  margin-top: 60px;
}
.test-heading {
  padding-bottom: 25px;
}
.testimonial-inner-wrapper {
  position: relative;
  text-align: center;
  justify-content: center;
  justify-items: center;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12.61px 0.39px rgba(0, 0, 0, 0.14);
}

.testimonial-profile img {
  width: 110px;
  height: 130px;
  position: absolute;
  top: -10%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.testimonial-profile-name {
  margin-top: 100px;
}

.testimonial-profile-name h4 {
  color: #0e476b;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-size: 20px;
}

.testimonial-profile-name p {
  margin: 0;
}

.testimonial-profile-name span {
  background-color: #dd1717;
  display: inline-block;
  height: 2px;
  vertical-align: top;
  width: 80px;
  margin: 20px 0;
}
.testimonial-company-name {
  font-size: 16px;
  font-weight: 600;
  color: #79bd12;
}

.testimonial-desc {
  padding-top: 20px;
}
