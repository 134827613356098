.separate-services-section {
  padding: 50px 0px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 10;
}
.separate-services-heading h3 {
  color: #0e476b;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.separate-service-outer-wrapper {
  height: 550px;
  padding: 80px 10px;
}

.separate-services-wrapper {
  position: relative;
  height: 300px;
}

.separate-services-wrapper .separate-services-img {
  width: 85%;
  height: 225px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 10px rgb(0 0 0 /8%);
  position: absolute;
  top: -5%;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s linear;
  border-radius: 10px;
}

.separate-services-wrapper .separate-service-inner-text-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  background: #fff;
  height: 300px;
  padding: 70px 25px 30px;
  z-index: 1;
  box-shadow: 0 5px 22px 0px rgba(15, 53, 103, 0.05);
  border-radius: 10px;
  overflow: hidden;
  transition: all ease 0.5s;
}

.separate-services-wrapper:hover .separate-services-img {
  width: 100%;
}

.separate-services-wrapper
  .separate-service-inner-text-wrapper:hover
  .separate-services-wrapper
  .separate-services-img {
  width: 100%;
}
.separate-services-wrapper .separate-service-inner-text-wrapper h3 {
  color: #0e476b;
  font-weight: 700;
  font-size: 25px;
  padding: 10px 0;
  z-index: 1;
}

.separate-services-wrapper .separate-service-inner-text-wrapper p {
  font-size: 15px;
  color: #676c71;
  letter-spacing: 0.2px;
  text-align: justify;
}

.separate-services-wrapper .services-konw-more {
  display: flex;
  justify-content: end;
}
.separate-services-wrapper .services-konw-more button {
  background-color: transparent;
  padding: 10px 0px;
  border-radius: 3px;
  border: none;
  color: #dd1717;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
  
}

.separate-services-wrapper .services-konw-more  .rightIcon {
  margin-left: 10px;
}
.separate-services-wrapper .services-konw-more  .rightIcon {
  animation: uparrow 0.6s infinite alternate ease-in-out;
}

@keyframes uparrow {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-0.4em);
  }
}




/* Media Queries */
@media (max-width: 576px) {
.separate-services-wrapper .separate-services-img{
  height: 190px;
}
}




.service-col-img {
  width: 100% !important;
  height: 550px;
}

.service-col-text {
  background-color: #273272;
  color: white;
  height: 550px;
}

.service-heading {
  text-align: center;
}

.service-inner-text {
  padding: 110px 60px;

}

.service-main-text {
  padding: 10px;
}

.service-blog-heading {
  font-size: 35px;
  padding: 15px;
}

.service-text-p {
  font-weight: none;
  font-size: 15px;
}

/* benifits */
.benefits-heading {
  text-align: center;
}

.benefits-area {
  position: relative;
  display: block;
  background: #f0f5f6;
  padding: 100px 0 93px;
}

.sec-title {
  position: relative;
  display: block;
  margin-top: -8px;
  padding-bottom: 55px;
}

.sec-title .sub-title {
  position: relative;
  display: block;
  padding-top: 13px;
}

.single-benefits-box .text h3 {
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 13px;
}

.single-benefits-box .text p {
  color: #757c7d;
}

.sec-title .sub-title p {
  font-size: 18px;
  line-height: 24px;
}

ul.row.benefits-content {
  --bs-gutter-x: 0px;
}

.benefits-area li.single-benefits-box-colum {
  position: relative;
  padding: 30px 30px 39px;
  list-style-type: none;
}

.benefits-area li.single-benefits-box-colum::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-image: -webkit-linear-gradient(90deg, rgb(247, 241, 235) 5%, rgb(15, 180, 195) 50%, rgb(247, 241, 235) 95%);
}

.benefits-area .single-benefits-box {
  position: relative;
  display: block;
  padding: 30px;
}

.benefits-area .single-benefits-box .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border: 1px solid #e0e6e7;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  background: linear-gradient(to right, #018795 0%, #0eced3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  z-index: 1;
}

.benefits-area .single-benefits-box .icon::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 1px;
  bottom: 0px;
  right: 0px;
  border: 1px solid #e0e6e7;
  transform: rotate(45deg);
}

.benefits-area .single-benefits-box .text {
  position: relative;
  display: block;
  padding-top: 26px;
}

.benefits-area li.single-benefits-box-colum::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(180deg, rgb(247, 241, 235) 10%, rgb(79, 213, 225) 50%, rgb(247, 241, 235) 90%);
}

.benefits-area .single-benefits-box .icon .icon-high {
  fill: teal;
}

.benefits-area .single-benefits-box .icon .icon-notification {
  fill: orange;
}

.benefits-area .single-benefits-box .icon .icon-safebox {
  fill: green;
}

.benefits-area .single-benefits-box .icon .icon-devlop {
  fill: yellow;
}

.benefits-area .single-benefits-box .icon .icon-shield {
  fill: pink;
}

.benefits-area .single-benefits-box .icon .icon-paperless {
  fill: purple;
}