/* What is Full Stack Development */

.full-stack-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.what-full-stack-section {
  background: url("../Images/FullStackDev/Slider 1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}

.what-full-stack-box-section {
  padding: 50px;
  display: flex;
  align-items: center;
  background-color: #0e476b;
  width: 100%;
  height: 80vh;
}

.what-full-stack-inner-box h3 {
  color: #fff;
  margin-bottom: 20px;
}

.what-full-stack-inner-box p {
  color: #fff;
}


/* Importance of learning Full stack development  */
.imp-learn-section{
  padding: 50px 0;
}

.imp-learn-heading{
  display: flex;
  align-items: center;
}

.full-stack-imp-heading h3{
margin-bottom: 20px;
text-align: center;
}

.imp-learn-heading p {
  height: 80px;
  width: 80px;
  background-color: #79bd12;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  box-shadow: 0 5px 7px rgba(0, 9, 128, 0.10),
      0 7px 10px rgba(0, 9, 128, 0.05);
}

.imp-learn-heading h4{
  color: #0e476b;
  padding-left: 10px;
  margin: 0;
}

.imp-learn-para {
  padding: 20px 0;
  text-align: justify;
}

.imp-learn-wrapper{
  padding: 10px 20px;
  height: 280px;
}

/* What You will gain after learning this course? */
.after-learning-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
  padding: 50px 0;
}

.after-learning-section-heading {
  padding: 0 100px;
  text-align: center;
}

.after-learning-wrapper,
.after-learning-wrapper2 {
  padding: 30px 50px 30px;
  position: relative;
}
.after-learning-wrapper2::after {
  content: "";
  width: 1px;
  height: 70px;
  background: #e6ecf0;
  position: absolute;
  top: 50%;
  left: 0%;
}

.after-learning-wrapper2::before {
  content: "";
  width: 1px;
  height: 70px;
  background: #e6ecf0;
  position: absolute;
  top: 50%;
  right: 0%;
}

.no-h3 {
  -webkit-text-stroke: 1px #79bd12;
  -webkit-text-fill-color: transparent;
  font-size: 130px;
  position: absolute;
  left: 20px;
  z-index: -1;
  opacity: 0.4;
}

.after-learning-text p {
  font-weight: 500px;
  font-size: 20px;
  margin-top: 100px;
}

@media (max-width: 1200px) {
  .what-full-stack-section {
    background-position: center;
  }
  .what-full-stack-box-section {
    width: 90%;
    padding: 0px 20px;
  }
  .web-dev-heading p::before {
    left: 30%;
  }
  .web-dev-heading p::after {
    right: 30%;
  }
  .what-full-stack-inner-box h3 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .what-full-stack-box-section {
    width: 50%;
    padding: 0px 20px;
  }
  .syllabus-wrapper ul {
    display: block;
  }
  .imp-learn-wrapper{
    height: auto;
  }
}

@media (max-width: 576px) {
  .what-full-stack-section {
    background: none;
    height: auto;
  }
  .what-full-stack-box-section {
    width: 100%;
    padding: 30px 20px;
    height: auto;
  }
  .web-dev-heading p::before {
    left: 10%;
  }
  .web-dev-heading p::after {
    right: 10%;
  }
  .what-full-stack-inner-box h3 {
    font-size: 31px;
  }
  .after-learning-section-heading {
    padding: 0px;
  }
  .after-learning-section-heading h3 {
    font-size: 30px;
  }
  .syllabus-wrapper ul {
    display: block;
  }
  .no-h3 {
    bottom: -65px;
    left: -25px
  }
  .after-learning-wrapper,
  .after-learning-wrapper2 {
    padding: 0px 50px;
  }
  .imp-learn-wrapper{
    height: auto;
  }
  .full-stack-imp-heading h3 {
    font-size: 30px;
  }
  .imp-learn-heading h4 {
    font-size: 22px;
  }
}
