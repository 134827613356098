.courses-section {
  background-color: #fff;
  padding: 50px 0;
  position: relative;
  z-index: 10;
}

.shape-20-1 {
  position: absolute;
  top: 45px;
  width: 400px;
  height: 400px;
  z-index: -1;
  opacity: 0.3;
  right: 95px;
  transform: rotate(0deg);
  transition: transform 30s linear;
  animation: rotate 30s infinite linear;
}
.shape-20-2 {
  position: absolute;
  top: 45%;
  width: 350px;
  height: 350px;
  z-index: -1;
  opacity: 0.2;
  left: 20%;
  transform: rotate(0deg);
  transition: transform 30s linear;
  animation: rotate 30s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.courses-bg-bottom {
  position: absolute;
  bottom: -200px;
  right: 0;
  width: 100%;
  /* background-position: top right -37%; */
}
.courses-heading {
  padding-bottom: 40px;
}
.courses-heading h3 {
  color: #0e476b;
  text-align: start;
}
.courses-heading h5 {
  color: #0e476b;
  font-size: 16px;
  text-align: start;
}
.courses-list-section {
  cursor: default;
}

.courses-list-outer-wrapper {
  position: relative;
}

.courses-list-outer-wrapper p {
  position: relative;
  padding: 5px 20px;
  text-align: start;
  border-radius: 0px;
  transition: border, border-image 10s ease-in-out;
  /* background: center linear-gradient(black, black) no-repeat; */
  background-origin: content-box;
  color: #0e476b;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.5s;
}
.courses-list-outer-wrapper p:hover {
  padding: 5px 35px;
  font-weight: 700;
}
.courses-list-outer-wrapper a {
  text-decoration: none;
}
.courses-list-outer-wrapper p:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  top: 50%;
  position: absolute;
  background: #79bd12;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 15px;
  height: 3px;
}
.courses-list-outer-wrapper p:hover:after {
  width: 25px;
  height: 3px;
  left: 0;
}

.courses-hover-img {
}

.courses-hover-img img {
  width: 100%;
  /* width: 500px; */
  /* height: 300px; */
}

.courses-name {
  text-align: start;
  padding: 20px 0;
}

.course-right-section h4 {
  margin-bottom: 0px !important;
}
.courses-wrapper {
  padding: 0px 50px;
}
/* Media Queries */
@media (max-width: 576px) {
  .course-right-section {
    display: none;
  }
  .shape-20 {
    display: none;
  }
  .courses-wrapper {
    padding-left: 40px;
  }
  .courses-list-outer-wrapper p {
    font-size: 15px;
  }
  .shape-20-2 {
    display: none;
  }  
}


/* corporate-banking css */
.corporate-banking-bg {
  background-image: url(https://fastwpdemo.com/newwp/finbank/wp-content/uploads/2022/09/corporate-banking-bg.jpg);
}

.corporate-banking {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 93px 0 70px;
  z-index: 1;
}

.corporate-banking-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  background-color: #1a1c23;
  background-blend-mode: luminosity;
  z-index: -1;
}

.corporate-banking-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(26, 28, 35);
  background: linear-gradient(90deg, rgba(26, 28, 35, 1) 0%, rgba(245, 248, 247, 1) 0%, rgba(245, 248, 247, 1) 30%, rgba(245, 248, 247, 1) 40%, rgba(245, 248, 247, 1) 55%, rgba(245, 248, 247, 0) 100%);
}

.corporate-banking-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f5f8f7;
  opacity: 0.80;
}

.corporate-banking__inner {
  position: relative;
  display: block;
  max-width: 670px;
  width: 100%;
  float: left;
  padding-left: 30px;
  z-index: 2;
}

.corporate-banking__inner .sec-title {
  margin-top: 0px;
  padding-bottom: 55px;
  margin: 0px 39px;
}

.corporate-banking__inner .sec-title h2 {
  color: var(--thm-black);
  font-size: 48px;
  line-height: 1.1em;
  font-weight: 500;
  text-transform: capitalize;
}
.corporate-banking__inner .sec-title .sub-title p {
  color: #797c7f;
  font-size: 18px;
  line-height: 24px;
}
.sec-title .sub-title {
  position: relative;
  display: block;
  padding-top: 13px;
}

.sec-title .sub-title p {
  font-size: 18px;
  line-height: 24px;
}

.corporate-banking__inner-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.corporate-banking__inner-content ul {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}

.corporate-banking__inner-content ul li {
  position: relative;
  display: block;
  float: left;
  width: 33.3333333333%;
  padding: 0 15px;
}

.single-features-of-banking--style2 {
  border-color: #e1e6e4;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

.single-features-of-banking {
  position: relative;
  display: block;
  border: 1px solid #e0e1e2;
  text-align: center;
  padding: 30px 0px 0;
  width: 170px;
  margin: 0 auto 30px;
  z-index: 1;

  height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-features-of-banking::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-right: 55px solid #30343c;
  border-top: 102px solid transparent;
  border-bottom: 102px solid transparent;
}

.single-features-of-banking--style2::before {
  border-right: 55px solid #f5f8f7;
}

.single-features-of-banking .icon-holder {
  font-size: 40px;
}

.corporate-banking-bg img {
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.single-features-of-banking h3 {
  font-size: 20px;
  line-height: 28px;
  padding: 0px 10px;
}

.single-features-of-banking--style2 h3 a {
  color: var(--thm-black);
  text-decoration: none;
}

.single-features-of-banking .btn-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding-top: 9px;
  z-index: 1;
}

.single-features-of-banking--style2 .btn-box::before {
  border-bottom: 55px solid #f5f8f7;
}

.single-features-of-banking .btn-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-bottom: 55px solid #30343c;
  border-left: 85px solid transparent;
  border-right: 85px solid transparent;
}

.single-features-of-banking .btn-box a {
  position: relative;
  display: inline-block;
  color: #a5a6aa;
  font-size: 15px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.single-features-of-banking--style2 .btn-box::before {
  border-bottom: 55px solid #f5f8f7;
}

.single-features-of-banking .btn-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-left: 85px solid transparent;
  border-right: 85px solid transparent;
}

.single-features-of-banking .btn-box a {
  position: relative;
  display: inline-block;
  color: #a5a6aa;
  font-size: 15px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.single-features-of-banking::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border: 1px solid var(--thm-base);
  z-index: -1;
  opacity: 0;
  transform: perspective(400px) scaleX(0.7);
  transform-origin: center;
}

