.footer-height {
  height: 90vh;
  position: relative;
}

footer {
  width: 100%;
  padding: 40px 60px;
  background: url("../Images/footerBg.jpg");
  background-position: center;
  position: fixed;
  bottom: 0px;
  z-index: 0;
  height: 90vh;
}

.round-shape-left {
  position: absolute;
  top: -1%;
  bottom: 0;
  left: -10%;
  z-index: 10;
  width: 400px;
  height: 75vh;
  transform: rotate(-40deg);
}

.round-shape-right {
  position: absolute;
  top: -90%;
  left: 0;
  z-index: 10;
  width: 500px;
  height: auto;
  transform: rotate(90deg);
}

.round-shape-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 500px;
  height: auto;
  transform: rotate(45deg);
}

.footer-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1px solid #757575;
  padding: 20px 0;
}

.footer-logo {
  width: 200px;
}

.footer-content-wrappper {
  padding: 20px 0;
  border-bottom: 0.1px solid #757575;
  z-index: 1000;
}

.footer-outer-wrapper {
  padding: 10px;
}

.footer-courses-wrapper {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.001),
    rgba(255, 255, 255, 0.1)
  );
  border-radius: 20px;
  height: 230px;
  padding-bottom: 20px;
  padding-left: 40px;
}

.footer-wrapper {
  width: 100%;
}

.footer-wrapper h6, .footer-office-inner-wrapper h6 {
  color: #fff;
  font-size: 25px;
}

.footer-wrapper ul, .footer-office-inner-wrapper ul {
  padding-left: 0;
}

.footer-wrapper ul li, .footer-office-inner-wrapper ul li {
  list-style: none;
  color: #fff;
  padding: 3px 0px;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  cursor: pointer;
}
.footer-wrapper ul li:hover {
  padding: 3px 7px !important;
}
.footer-office-wrapper .footer-office-inner-wrapper ul li,
.footer-services-wrapper .footer-wrapper ul li,
.footer-info-wrapper .footer-wrapper ul li {
  display: flex;
}

.footer-office-text {
  padding-left: 5px;
}

.copyright-wrapper {
  text-align: center;
  padding-top: 30px;
}

.copyright-wrapper p {
  color: #fff;
}
.footer-content-wrappper .footer-wrapper a {
  text-decoration: none;
  color: #fff;
}

/* Social icons */

.social-icon-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0 !important;
}

.social-icon-wrapper li {
  list-style: none;
  display: flex;
}

.social-icon-wrapper li a {
  padding: 0 10px;
}

.social-icon-wrapper li a .social-icon {
  color: #fff;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
}

.social-icon-wrapper li {
  list-style: none;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 10px;
}
.social-icon-wrapper li::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  filter: blur(10px);
  z-index: 10;
  opacity: 0;
  transition: 0s;
}
.social-icon-wrapper li:hover::before {
  opacity: 1;
}
.social-icon-wrapper li a {
  position: relative;
  /* display: inline-block; */
  display: contents;
  width: 100%;
  height: 100%;
  transition: 0s;
}
.social-icon-wrapper li:hover a {
  transform: translateY(-50px);
}
.social-icon-wrapper li a::before,
.social-icon-wrapper li a::after {
  content: "";
  font-family: "fontAwesome";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  text-align: center;
  background: #fff;
  color: #262626;
  font-size: 23px;
  transition: 0.5s;
}
.social-icon-wrapper li a::before {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  transform-origin: top;
  transform: rotate(0deg) translateY(0px);
}
.social-icon-wrapper li a::after {
  color: #fff;
  transform-origin: bottom;
  transform: rotateX(90deg) translateY(10px);
}
.social-icon-wrapper li:hover a::before {
  transform: rotateX(90deg) translateY(-10px);
}
.social-icon-wrapper li:hover a::after {
  transform: rotateX(0deg) translateY(0px);
}

.social-icon-wrapper li:nth-of-type(1) a::before,
.social-icon-wrapper li:nth-of-type(1) a::after {
  content: "\f09a";
  top: 10px;
}
.social-icon-wrapper li:nth-of-type(2) a::before,
.social-icon-wrapper li:nth-of-type(2) a::after {
  content: "\f16d";
  top: 10px;
}
.social-icon-wrapper li:nth-of-type(3) a::before,
.social-icon-wrapper li:nth-of-type(3) a::after {
  content: "\f0e1";
  top: 10px;
}
.social-icon-wrapper li:nth-of-type(4) a::before,
.social-icon-wrapper li:nth-of-type(4) a::after {
  content: "\f231";
  top: 10px;
}
.social-icon-wrapper li:nth-of-type(5) a::before,
.social-icon-wrapper li:nth-of-type(5) a::after {
  content: "\f167";
  top: 10px;
}

.social-icon-wrapper li:nth-of-type(1) a::after {
  background: #3b5999;
}
.social-icon-wrapper li:nth-of-type(2) a::after {
  background: radial-gradient(
    circle farthest-corner at 35% 90%,
    #fec564,
    transparent 50%
  ),
  radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(
    ellipse farthest-corner at 20% -50%,
    #5258cf,
    transparent 50%
  ),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(
    ellipse farthest-corner at 60% -20%,
    #893dc2,
    transparent 50%
  ),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  linear-gradient(
    #6559ca,
    #bc318f 30%,
    #e33f5f 50%,
    #f77638 70%,
    #fec66d 100%
  );
}
.social-icon-wrapper li:nth-of-type(3) a::after {
  background: #0072b1 ;
}
.social-icon-wrapper li:nth-of-type(4) a::after {
  background: #c8232c ;
}
.social-icon-wrapper li:nth-of-type(5) a::after {
  background: #fa120a ;
}

/* Media Queries */
@media (max-width: 576px) {
  footer {
    position: static !important;
    padding: 20px;
    background: linear-gradient(
      rgb(14, 71, 107),
      rgb(14, 71, 107),
      rgb(121, 189, 18)
    ) !important;
    background-repeat: no-repeat;
    height: fit-content !important;
  }
  .footer-logo-wrapper{
    display: flex;
    justify-content:center;
  }
 
  .footer-top-section {
    display: block;
  }
  .social-icon-wrapper {
    padding: 20px 0;
    display: block;
  }
  .bubble-holder {
    height: auto;
  }
  .round-shape-left,
  .round-shape-right,
  .round-shape-bottom {
    display: none;
  }
  .wp-float-btn {
    width: 60px;
    height: 60px;
    bottom: 90px;
    right: 30px;
  }
  .tawk-min-container .tawk-button-circle.tawk-button-large {
    width: 50px !important;
    height: 50px !important;
  }
  .footer-courses-wrapper{
    padding-left: 0;
  }
  .footer-outer-wrapper {
    padding: 5px 15px;
}
.footer-courses-wrapper{
  height: 210px;
  margin-bottom: 10px;
}
.footer-content-wrappper{
  padding: 10px 0;
}
.social-icon-wrapper li{
  width: 35px;
  height: 35px;
}
}

@media (max-width: 768px) {
  footer {
    position: static !important;
    padding: 20px;
    background: linear-gradient(
      rgb(14, 71, 107),
      rgb(14, 71, 107),
      rgb(121, 189, 18)
    ) !important;
    background-repeat: no-repeat;
    height: fit-content !important;
  }
}

@media (max-width: 1200px) {
  .footer-height footer {
    height: 95vh ;
  }
}

@media (min-width: 1400px) and (max-width: 1920px) {
  .footer-height footer {
    height: 75vh ;
  }
}
