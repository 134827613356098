@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  /* margin: 0; */
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row > * {
  margin: 0 !important;
  padding: 0 !important;
}

h3 {
  font-size: 35px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #0e476b;
  /* color: #79bd12; */
}

h4 {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

h5 {
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0;
}

.d-flex {
  display: flex;
  align-items: center;
}

.wp-float-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 90px;
  right: 25px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 5000;
  padding: 10px;
}

.contentBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.buttonBox {
  position: relative;
  flex: 20%;
}

.border-btn {
  width: 100%;
  background-color: #79bd12;
  padding: 10px 20px;
  color: #fff;
  /* border-radius: 5px; */
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  outline: none;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  cursor: pointer;
}

/* FIRST BUTTON */

.border {
  position: absolute;
  background: none;
  transition: all 0.5s ease-in-out;
  border: none !important;
}

#first > .border:nth-of-type(1) {
  top: 0;
  left: 0;
  border-left: 1px solid #0e476b !important;
  border-top: 1px solid #0e476b !important;
  width: 30px;
  height: 30px;
}

#first > .border:nth-of-type(2) {
  bottom: 0;
  right: 0;
  border-right: 1px solid #0e476b !important;
  border-bottom: 1px solid #0e476b !important;
  width: 30px;
  height: 30px;
}

#first:hover .border {
  width: 102%;
  height: 105%;
}

/* SERVICES CONTACT FORM */

.service-contact-section {
  background: url("./Assets/Images/shape-12.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  position: relative;
}

.service-contact-wrapper {
  padding: 0 50px;
}

.service-contact-inner-wrapper {
  padding: 25px 0;
}

.service-contact-img-wrapper {
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.service-contact-img-wrapper img {
  width: 400px;
  bottom: 0;
}

.service-contact-heading h3 {
  text-align: center;
  font-size: 40px;
  color: #0e476b;
}
.service-contact-form-control:focus {
  outline: none;
}
.service-contact-form-control::placeholder {
  color: #a0a0a0;
}
.service-contact-btnSubmit {
  display: flex;
  justify-content: center;
}

.service-contact-btnSubmit button {
  font-weight: 600;
  border: none;
  padding: 10px 30px;
  background-color: #dd1717;
  color: #fff;
}

.service-contact-inputWrapper {
  padding: 10px;
  position: relative;
}

.service-contact-form-control,
.service-contact-form-select {
  padding: 10px;
  border: 0.5px solid #e2eef2;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
}

.service-contact-input-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto 0;
  color: #a0a0a0;
}

/* Sticky button */
.sticky-reg-btn-wrapper {
  position: fixed;
  left: -50px;
  /* transform: rotate(-90deg); */
  z-index: 999;
  top: 50%;
}

.sticky-reg-btn {
  background-color: #79bd12;
  color: #fff;
  border-radius: 0;
  display: inline-block;
  letter-spacing: 0.4px;
  font-size: 18px;
  padding: 15px 20px;
  font-weight: 700;
  /* height: 50px; */
  line-height: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  /* width: 120px; */
  animation: sticky-reg-pulse 1.5s infinite;
}

@keyframes sticky-reg-pulse {
  0% {
    box-shadow: 0 0 0 0 #79bd12;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
.sticky-reg-modal {
  display: block;
  right: 0;
  height: fit-content;
  margin: auto;
  border-radius: 10px;
  --bs-offcanvas-width: 700px !important;
  overflow: hidden;
}
.sticky-close-btn-wrapper {
  border: none;
}

.sticky-close-btn-icon {
  position: absolute;
  top: -5px;
  right: -3px;
  background-color: #0e476b;
  opacity: 0.9;
  color: #fff !important;
  padding: 2px !important;
  border: none;
  border-radius: 50% !important;
  z-index: 100;
}

.sticky-reg-modal-header {
  padding: 0px;
  display: flex;
  justify-content: end;
}

.sticky-reg-modal-header button {
  position: absolute;
  right: 6px;
  background-color: #fff;
  top: 7px;
  opacity: 1;
}

.sticky-reg-modal-body {
  padding: 0;
}

.sticky-reg-modal-content {
  padding: 20px;
}

.sticky-reg-modal-content .sticky-reg-text {
  padding-bottom: 20px;
  color: #79bd12;
  margin: 0;
}

.sticky-reg-img-wrapper {
  height: 100%;
  background-color: #79bd12;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-left: 172px;
}

.sticky-reg-img-wrapper img {
  width: auto;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.sticky-reg-modal-content h3 {
  text-transform: uppercase;
  font-size: 30px;
  margin: 0;
}

.sticky-reg-submit-btn-wrapper {
  display: flex;
  justify-content: center;
}

.sticky-reg-submit-btn {
  background-color: #0e476b;
  padding: 5px 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.reg-form-errors {
  font-size: 13px;
  color: #dd1717 !important;
  margin-bottom: 0px !important;
  padding-top: 3px;
}

.sticky-reg-submit-btn:hover {
  background-color: #79bd12;
  color: #fff;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

/* button hover effect */
/* green to blue */
.green-blue-btn {
  padding-right: 5px;
}
.green-blue-btn button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;
}
.green-blue-btn button::before {
  position: absolute;
  top: 110px;
  left: -50px;
  right: -50px;
  height: 170px;
  content: "";
  background: #79bd12;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  border-radius: 50%;
  transition-duration: 800ms;
  z-index: 1;
  color: #ffffff;
}
.green-blue-btn button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  opacity: 1;
  transform: scaleX(1);
  transition-duration: 800ms;
  z-index: -1;
  color: #ffffff;
}

.green-blue-btn button::after {
  background-color: #79bd12;
}

.green-blue-btn button::before {
  background-color: #0e476b;
}

.green-blue-btn button:hover::before {
  top: 0%;
  left: -70px;
  right: -70px;
}

.green-blue-btn button:hover::after {
  transform: scaleX(0);
  transition-duration: 1500ms;
}

.green-blue-btn:hover .green-blue-btn button {
  color: #fff;
}
.green-blue-btn button .btn-text {
  position: relative;
  z-index: 1;
  color: #fff;
}
/* green to blue */
/*  blue to green  */
.blue-green-btn button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;
}
.blue-green-btn button::before {
  position: absolute;
  top: 110px;
  left: -50px;
  right: -50px;
  height: 170px;
  content: "";
  background: #0e476b;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  border-radius: 50%;
  transition-duration: 800ms;
  z-index: 1;
  color: #ffffff;
}
.blue-green-btn button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  opacity: 1;
  transform: scaleX(1);
  transition-duration: 800ms;
  z-index: -1;
  color: #ffffff;
}

.blue-green-btn button::after {
  background-color: #0e476b;
}

.blue-green-btn button::before {
  background-color: #79bd12;
}

.blue-green-btn button:hover::before {
  top: 0%;
  left: -70px;
  right: -70px;
}

.blue-green-btn button:hover::after {
  transform: scaleX(0);
  transition-duration: 1500ms;
}

.blue-green-btn:hover .blue-green-btn button {
  color: #fff;
}
.blue-green-btn button .btn-text {
  position: relative;
  z-index: 1;
  color: #fff;
}
/* blue to green  */

.offcanvas{
  transition: transform 1s ease-in-out;
}

/* Media Queries */
@media (max-width: 576px) {
  .sticky-reg-img-wrapper img {
    display: none;
  }
  .sticky-reg-modal-content {
    padding: 10px;
  }
}
