.it-training {
    padding: 50px 0; position: relative;
    background: #fff;
    z-index: 10;
  
  }
  
  .it-train-edu-text h5 {
    font-size: 13px;
    color: #676c71;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .it-train-edu-text h3,
  .trending-category h3 {
    font-size: 40px;
    letter-spacing: 0.2px;
    color: #0e476b;
  }
  
  .it-train-coaching-text h3 {
    font-size: 35px;
    letter-spacing: 0.2px;
    color: #0e476b;
    font-weight: 600;
  }
  
  .trending-category h3 {
    padding: 50px 0;
    font-weight: 600;
  }
  
  .trending-category h3 {
    text-align: center;
  }
  
  .it-main-wrapper {
    padding: 50px 0;
  }
  
  .it-train-edu-text p {
    font-size: 16px;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .it-train-edu-img img {
    width: 100%;
  }
  
  .it-train-edu-img p {
    padding-top: 20px;
    font-size: 16px;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .trend-section {
    padding: 15px;
  }
  
  .trend-wrapper {
    box-shadow: 0 0 20px 3px rgb(0 0 0/5%);
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .trend-wrapper div {
    display: grid;
    place-items: center;
  }
  
  .trend-services-img {
    width: 100px;
    display: flex;
    justify-content: center;
  }
  
  .trend-text-btn {
    padding-top: 20px;
  }
  
  .trend-text-btn p {
    font-size: 20px;
    color: #0e476b;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .trend-text-btn button {
    background-color: #0e476b;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .it-coaching-img img {
    width: 100%;
  }
  
  .it-train-coaching-text .it-train-para {
    padding-top: 20px;
    font-size: 16px;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .it-coaching-icon-text{
    display: flex;
    align-items: center;
  }
  
  .it-coaching-icon-text .coching-icon-arrow{
    color: rgb(2, 136, 2);
    margin-right: 10px;
  }
  
  .it-coaching-icon-text .coaching{
    color: #676c71;
    font-size: 16px;
    margin: 0;
    padding: 10px 0;
  }
  
  .red-blue-btn{
    display: flex;
    padding-top: 20px;
  }
  
  .red-blue-btn-wrapper{
    padding: 15px;
  }
  
  .trend-text-red-btn button {
    background-color: #0e476b;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .trend-text-blue-btn button {
    background-color: #79bd12;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .trend-text-blue-btn button{
    display: flex;
    align-items: center;
  }
  
  .trend-text-blue-btn button .start-here-btn{
    color: #fff;
    margin: 0;
    padding-right: 10px;
  }
  
  .start-here-btn{
    color: #fff;
  }
  
  .community-text h3{
    font-size: 40px;
    letter-spacing: 0.2px;
    color: #0e476b;
    font-weight: 600;
    text-align: center;
    width: 90%;
    margin-bottom: 35px;
  }
  
  .community-text h5 {
    font-size: 22px;
    margin: 0 0 60px;
    font-weight: 600;
    color: #7b7b8c;
    text-align: center;
    margin-bottom:  0 0 60px;
  }
  
  .comm-btn{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .comm-btn button{
    display: flex;
    align-items: center;
    background-color: #0e476b;
    padding: 15px 30px 16px;
    color: #fff;
    border: none;
    border-radius: 3px;
    transition: all .5s ease;
  }
  
  .comm-btn button .network-btn{
    color: #fff;
    margin: 0;
    padding-right: 10px;
  }
  
  .learn-section{
    background-color: rgb(249, 249, 249);
    padding: 100px 0;
  }
  
  .learn-text h5{
    font-size: 13px;
    color: #676c71;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    margin: 0 0 15px;
  }
  
  .learn-text h3{
    font-size: 40px;
    letter-spacing: 0.2px;
    color: #0e476b;
  }
  
  .learn-text p{
    font-size: 16px;
    color: #676c71;
    letter-spacing: 0.2px;
    padding: 15px 0 0;
  }
  
  .it-count-wrappper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .learn-count h4{
    font-size: 40px;
    line-height: 48px;
    margin: 0;
    font-weight: 900;
    color: #0e476b;
  }
  
  .learn-count p{font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin: 15px 0 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #5f5f5f;}