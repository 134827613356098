.whatIsDmSection {
  padding: 50px 0px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.whatIsDmImage img {
  width: 100%;
}
.whatIsDmWrapper {
  /* padding-top: 20px; */
}
.DMPointsWrapper {
  display: flex;
}
.whatIsDmHeading h3 {
  color: #0e476b;
}
.DMPoints {
  /* padding: 50px; */
  padding: 20px 0px;
}
.DMPoints h5 {
  color: #3c3c3c;
}
.whatIsDmText {
  margin-left: 40px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.whatIsDmText p {
  padding-top: 10px;
  color: #3c3c3c;
}

.whatIsDmText p a{
  text-decoration: none;
  color: #0e476b;
  }
  .whatIsDmText p a:hover{
    color: #0e476b;
  }

/* Media Queries */
@media (max-width: 576px) {
  .whatIsDmText{
    margin-left: 0;
  }
}