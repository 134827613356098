.web-course-section {
  position: relative;
  background: #fff;
  z-index: 10;
}
.web-design-crs-banner {
  background: url("../Images/WebDesign/LearnWebDesignCourseInPune.jpg");
  /* background: linear-gradient(#0e476b, #79bd12); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 400px;
  padding: 0px;
  display: flex;
  align-items: end;
  margin-bottom: 50px;
}
.web-design-banner-content {
  padding: 30px;
  background: rgba(0,0,0,0.8);
  width: 100%;
}

.web-design-banner-content h3 {
  color: #fff;
}
.web-design-banner-content p {
  color: #fff;
  font-size: 18px;
}
.web-design-banner-content p a,.web-design-banner-content p a:hover{
  text-decoration: none;
  color: #fff;
}
.web-course-wrapper .what-is-web {
  background: url("../Images/aboutUsSection1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.web-course-subwrapper {
  position: relative;
}
.webcourse-right {
  background-color: rgb(0, 71, 107, 0.7);
  /* height: 400px; */
  display: flex;
  align-items: center;
}
.webcourse-right-content {
  padding: 50px;
}
.webdesign-crs-title h3 {
  color: #fff;
}
.webCourse-text p {
  color: #fff;
  padding-bottom: 5px;
}
/* section2 */
.what-is-ui-wrapper .curve {
  /* shape-outside: ellipse(100px 200px at 50% 50%);
  width: 300px;
  height: 500px;
  float: left; */
  /* background-color: #0e476b; */
  shape-outside: ellipse(149px 159px at 0% 50%);
  width: 334px;
  height: 500px;
  float: left;
}
.ui-image-wrapper .crs-ui-img {
  width: 100%;
}
.what-is-ui-section {
  padding-top: 50px;
}
.ui-content-wrapper {
  padding: 20px;
}
.ui-content-wrapper p {
  padding-bottom: 20px;
  color: #3c3c3c;
}
/* section3 */
.what-is-ux-wrapper .curve {
  /* shape-outside: ellipse(100px 200px at 50% 50%);
  width: 300px;
  height: 500px;
  float: left; */
  /* background-color: #0e476b; */
  shape-outside: ellipse(149px 159px at 0% 50%);
  width: 334px;
  height: 500px;
  float: right;
}
.ux-image-wrapper .crs-ux-img {
  width: 100%;
}
.what-is-ux-section {
  padding: 50px 0px;
}
.ux-content-wrapper {
  padding: 20px;
}
.ux-content-wrapper p {
  padding-bottom: 20px;
  color: #3c3c3c;
}
/* tabs */
.tab-content-section{
  padding-bottom: 50px;
}
.tab-content-section .nav-class {
  background: linear-gradient(#0e476b, #79bd12);
  color: #fff;
  border: 1px solid #0e476b;
}
.tab-content-section .nav-tabs .nav-link {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.tab-content-section .nav-link {
  color: #fff;
}
.tab-content-section .nav-tabs .nav-link:hover {
  border-color: transparent !important;
}
.tab-content-section .nav-tabs {
  border-bottom: 0px;
}
/* section 4 */
.section-html,
.section-css,
.section-js,
.section-bootstrap,
.section-wordPress,.section-react {
  padding: 30px 0px;
}
.html-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.html-image-wrapper {
  width: 100%;
}
.html-wrapper p {
  /* color: #fff; */
}
.html-content-wrapper,
.js-content-wrapper,
.wordpress-content-wrapper {
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-content-wrapper,
.bootstrap-content-wrapper ,.react-content-wrapper{
  padding-right: 20px;
}

.css-content p,
.js-content p,
.html-content p,
.bootstrap-content p,
.wordpress-content p {
  padding-bottom: 10px;
}
.css-image-wrapper,
.crs-html-img,
.crs-css-img,
.crs-bootstrap-img,
.crs-js-img,
.crs-bootstrap-img,
.crs-wordpress-img {
  width: 100%;
}

.section-web-eligibility {
  background-color: #fff;
  z-index: 10;
  position: relative;
}
.web-eligibility {
  padding: 50px 0;
  background: linear-gradient(#0e476b, #79bd12);
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.eligibility-section {
  padding-top: 20px;
  width: 50%;
  margin: 0 auto;
}
.eligibility-heading h3,
.eligibility-heading h5 {
  color: #fff;
  text-align: center;
}
.web-eligibility-list {
  display: flex;
  align-items: center;
}
.web-eligibility-list p {
  color: #fff;
  font-size: 20px;
}
.web-eligibility-list .eligibility-icon {
  color: yellow;
  padding-right: 5px;
}

.faq-web-section {
  background: #e6ecf0;
  padding: 50px 0px;
  position: relative;
  z-index: 10;
}
.faq-web-title h3 {
  color: #0e476b;
  padding-bottom: 20px;
}
.faq-web-img-wrapper {
  display: flex;
  justify-content: center;
}
.faq-que-web-img {
  width: 300px;
  height: 365px;
}

/* Media Queries */
@media (max-width: 576px) {
  .web-design-crs-banner {
    padding: 10px;
  }
  .web-design-banner-content {
    padding: 0px;
  }
  .webcourse-right-content {
    padding: 10px;
  }
  .what-is-ui-wrapper .curve {
    display: none;
  }
  .ui-content-wrapper,
  .ux-content-wrapper,
  .what-is-ux-section {
    padding: 0;
  }
  .faq-que-web-img {
    width: 200px;
    height: 265px;
  }
  .faqsection {
    display: flex;
    flex-direction: column-reverse;
  }
}
