.header-height {
  height: 115px;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.hidden {
  display: none;
}
.header-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
}

.menu-icon {
  display: none;
}

.logo {
  height: 60px;
}

.top-header {
  border-bottom: 1px solid rgba(2, 45, 98, 0.1);
  background: #fff;
}

.top-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.top-header-left-wrapper {
  width: 40%;
  /* background-color: #79bd12; */
  background-color: #0e476b;
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: relative;
}

.top-header-left-wrapper span {
  position: absolute;
  right: -25px;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  /* border-bottom: 44px solid #79bd12; */
  border-bottom: 44px solid #0e476b;
  transform: rotate(180deg);
  z-index: 0;
}

.top-header-right-wrapper {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.addmission-text {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  padding: 7px 0;
  margin: 0;
  text-indent: 50px;
}

.header-time {
  color: #0e476b;
  padding-right: 20px;
}

.header-time p {
  padding-left: 5px;
  font-size: 15px;
  color: #0e476b;
}

.header-icon {
  margin: 0 2px;
  border: 0.5px solid #616c71;
  border-radius: 50%;
  padding: 2px;
  background-color: #0e476b;
  color: #fff;
}
.top-social-icon a {
  cursor: pointer;
}
/* Main header */
.main-header {
  box-shadow: 2px 2px 4px 0 rgb(2 45 98/10%);
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.header-logo-wrapper {
  width: 15%;
}

.header-menu {
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: end;
}

.header-menu ul {
  display: flex;
  margin: 0;
}

.header-menu ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.header-menu ul li a {
  text-decoration: none;
  color: #0e476b;
  font-size: 18px;
  font-weight: 600;
}

.header-menu ul li a p {
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in;
}

.header-menu ul li a:hover {
  color: #79bd12;
}

.header-right-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 30%;
}
.btn-career-guidance {
  position: relative;
}
.btn-career-guidance button {
  /* background-color: #0e476b; */
  background-color: #79bd12;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  border: 0 solid;
}

.header-mobile {
  padding-right: 20px;
}
.header-mobile a {
  text-decoration: none;
  color: #0e476b;
}
.header-mobile a {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
    color: #dd1717;
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Mobile View */

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  z-index: 9999;
}

.mb-social-icon-section .social-icons {
  color: #0e476b;
  margin-right: 8px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.mb-social-icon-section ul {
  margin-bottom: 20px !important;
  display: flex;
  padding-left: 0;
}
.mb-social-icon-section ul li {
  list-style-type: none;
}
.mb-social-icon-section ul li a {
  padding-right: 0;
}

.accordion {
  border: none;
  outline: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  /* color: var(--bs-accordion-active-color); */
  background-color: transparent;
  box-shadow: none;
}
.mb-menu .accordion-button {
  padding: 0;
  color: #0e476b;
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
}
.accordion-header {
  border-bottom: 1px solid #dfdfdf;
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-align: justify;
  color: #0e476b;
  text-decoration: none;
  padding: 10px 10px;
}

.accordion-button:not(.collapsed) {
  color: #0e476b;
}

/* .accordion-button:not(.collapsed)::after {
  background-image: none;
} */

.accordion-button p {
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-align: justify;
  color: #0e476b;
  text-decoration: none;
  padding: 10px 10px;
}

.accordion-item {
  border: none;
}
.accordion-body {
  padding: 0;
}

.accordion-body p {
  border-bottom: 1px solid #a7a7a7;
  font-size: 15px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-align: justify;
  color: #0e476b;
  text-decoration: none;
  padding: 8px 20px;
  margin: 0;
}
.accordion-body p a {
  color: #0e476b;
  text-decoration: none;
}
/* .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: none !important;
  border-bottom-left-radius: none !important;
  font-weight: 600;
  color: #0e476b;
} */
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}
.accordion-button::after{
  width: 15px !important;
  height: 15px !important;
  background-size: auto;
}

.reg-btn {
  border: none;
}

.mb-search-bar {
  padding: 20px 0;
  position: relative;
}

.mb-search-bar input {
  color: #0e476b;
  padding: 6px;
  width: 100%;
}

.mb-search-bar .mb-search-icon {
  position: absolute;
  top: 30px;
  left: 8px;
}

.mb-menu {
  display: block;
  justify-content: end;
  align-items: center;
  padding: 10px 0;
}
.mb-menu .menu-content .mb-header-link {
  border-bottom: 1px solid #dfdfdf;
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-align: justify;
  color: #0e476b;
  text-decoration: none;
  padding: 10px 10px;
}
.mb-menu .menu-content {
  display: grid;
  padding-left: 0 !important;
}
.menu-content {
  display: contents;
}
.mb-menu .menu-content .accordion-button:focus {
  box-shadow: none !important;
}
/* Media Queries */
/* @media (max-width: 576px) { */
@media (min-width: 320px) and (max-width: 767px) {
  .header-height {
    height: 172px !important;
  }
  .header-icon {
    margin: 0 5px;
  }
  .menu-icon {
    display: block;
  }
  .header-menu {
    display: none;
  }
  .top-header-left-wrapper {
    width: 100%;
  }
  .top-header-wrapper {
    display: block;
  }
  .top-header-left-wrapper span {
    display: none;
  }
  .top-header-right-wrapper {
    display: flex !important;
    justify-content: space-between !important;
    padding: 8px 20px !important;
  }
  .top-social-icon {
    display: none !important;
  }
  .header-right-wrapper {
    display: none;
  }
  .header-logo-wrapper {
    width: 100%;
  }
  .logo-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-icon {
    color: #0e476b;
  }
  .header-mobile {
    padding-right: 0;
  }
  .header-mobile p {
    text-align: center;
    padding-bottom: 5px 0 !important;
  }
  .mb-btn-career-guidance button {
    padding: 7px 10px !important;
  }
  .mb-header-btn-wrapper button {
    padding: 7px 10px !important;
  }
  .header-time {
    padding-right: 0px;
    padding: 0 !important;
  }
  .mb-header-btn-wrapper{
    padding-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .header-height {
    height: 125px;
  }
  .menu-icon {
    display: block !important;
  }
  .header-menu {
    display: none;
  }
  .top-header-left-wrapper {
    width: 100%;
  }
  .top-header-wrapper {
    display: block;
  }
  .top-header-left-wrapper span {
    display: none;
  }
  .top-header-right-wrapper {
    display: grid;
    place-content: center;
    padding: 10px 0;
  }
  .top-social-icon {
    display: flex;
    justify-content: center;
  }
  .header-right-wrapper {
    display: none;
  }
  .header-logo-wrapper {
    width: 100%;
  }
  .logo-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-icon {
    color: #0e476b;
  }
  .header-mobile p {
    text-align: center;
    padding: 3px 0;
  }
  .mb-btn-career-guidance button {
    padding: 10px;
  }
  .mb-header-btn-wrapper button {
    padding: 10px;
  }
  .header-time {
    padding-right: 0px;
    padding: 3px 20px;
  }
  .course-section {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .header-height {
    height: 125px;
  }
  .menu-icon {
    display: block !important;
  }
  .header-menu {
    display: none !important;
  }
  .top-header-left-wrapper {
    width: 100%;
  }
  .top-header-wrapper {
    display: block;
  }
  .top-header-left-wrapper span {
    display: none;
  }
  .top-header-right-wrapper {
    display: flex;
    place-content: center;
    padding: 10px 0;
  }
  .top-social-icon {
    display: flex;
    justify-content: center;
  }
  .header-right-wrapper {
    display: none;
  }
  .header-logo-wrapper {
    width: 100%;
  }
  .logo-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-icon {
    color: #0e476b;
  }
  .header-mobile p {
    text-align: center;
    padding: 3px 0;
  }
  .mb-btn-career-guidance button {
    background-color: #79bd12;
    padding: 10px;
    border: none;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
  }
  .mb-header-btn-wrapper button {
    padding: 10px;
    border: none;
    background-color: #0e476b;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
  }
  .header-time {
    padding-right: 0px;
    padding: 3px 20px;
  }
  .course-section {
    width: 100% !important;
  }
}

@media (min-width: 1400px) and (max-width: 1920px) {
  .get-in-touch-modal {
    top: 20%;
  }
}
