.who-can-do-this-course-section {
  background-color: #fff;
  z-index: 10;
  position: relative;
}
.who-can-do-this-course {
  padding: 50px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), #000),
    url("../Images/bg-guarenteed.jpg") fixed center center;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.course-heading h3 {
  font-size: 40px;
}
.course-heading h3,
.course-heading h5 {
  color: #fff;
  text-align: center;
}

.course-section {
  padding-top: 30px;
  width: 60%;
  margin: 0 auto;
}

.who-can-do-this-course-list {
  display: flex;
  align-items: center;
}

.who-can-do-this-course-list p {
  color: #fff;
  font-size: 25px;
  padding-left: 5px;
}

.who-can-do-this-course-list .course-icon {
  color: yellow;
}


/* Media Queries */
@media (max-width: 576px) {
  .course-section {
    width: 100% !important;
    margin: 0 !important;
  }
  .who-can-do-this-course-list p{
    font-size: 20px;
  }
}