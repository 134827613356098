.banner {
  background-color: #fff;
  position: relative;
  z-index: 1000;
}
.banner-section1,
.banner-section2,
.banner-section3,
.banner-section4 {
  padding: 20px 100px;
  position: relative;
  z-index: 10;
  height: 100vh;
  display: flex;
  align-items: center;
}
.banner-section1 {
  background-image: linear-gradient(to right, #79bd12, #0e476b);
}
.banner-section2 {
  background-image: linear-gradient(to right, #771d78, #d30466);
}

.banner-section3 {
  background-image: linear-gradient(to right, #cb2f22, #f29f20);
}

.banner-section4{
  background-image: linear-gradient(to right, #21a1f7, #3f20f2);
}

.banner-wrapper {
  display: flex;
  align-items: center;
  padding: 0 100px;
}

.banner-web-img-wrapper,
.banner-digital-img-wrapper,
.banner-graphics-img-wrapper {
  width: 100%;
  position: relative;
}

.banner-web-img-wrapper img,
.banner-digital-img-wrapper img,
.banner-graphics-img-wrapper img{
  width: 500px;
}

.HomeGraphicsBGPage{
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;
  z-index: -1;
}

.banner-p1 {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: capitalize;
}

.banner-h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 0.2px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Buttershine ", serif;
}

.banner-width{
  width: 100%;
}

/* Media Queries */
@media (max-width: 576px) {
  .web-page img {
    width: 200px;
  }
  .banner-section1,
  .banner-section2,
  .banner-section3,
  .banner-section4  {
    padding: 20px;
  }
  .banner-h1 {
    font-size: 30px;
  }
  .banner-width{
    padding: 20px 0;
  }
}
