.setionCourse {
  /* padding: 50px 0px; */
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.course-title-text h3 {
  color: #fff;
}
.course-title-text {
  padding: 0px 20px 40px 300px;
}
.course-card-section {
  padding: 0px 200px;
  z-index: 10;
  position: relative;
}

.course-card-Wrapper {
  padding: 10px 10px;
}
.card-content-text {
  height: 200px;
  padding: 10px 20px;
}
.courseWrapper {
  position: absolute;
  top: 0;
  padding: 50px 0px;
  background: #0b3b58;
}
.adjust-space-bg {
  background: #0b3b58;
}
.adjust-space-bg-inner {
  /* padding: 80px; */
  padding: 16px;
}
.course-image img {
  position: sticky;
  top: 0;
  bottom: 0;
  height:550px;
  width: 25%;
  z-index: 1;
}
.course-image1 img {
  position: sticky;
  top: 31%;
  bottom: 0;
  height:550px;
  width: 25%;
  z-index: 1;
}
.course-image2 img {
  position: sticky;
  top: 62%;
  bottom: 0;
  height:550px;
  width: 25%;
  z-index: 1;
}
.course-image3 img {
  position: sticky;
  top: 124%;
  bottom: 0;
  height: 500px;
  width: 25%;
  z-index: 1;
}
.card-content {
  box-shadow: 0 0px 3px 0px rgba(255, 255, 255);
  background: #f7f7f7;
  z-index: 10;
}
.card-content h3 {
  font-size: 25px;
  color: #0e476b;
  padding: 10px 5px;
}
.course-title-text p {
  color: #fff;
}
.card-content p {
  color: #3c3c3c;
}
.course-inner-image {
  width: 100%;
  height: 200px;
}
.course-info {
  padding: 10px 20px 10px 0px;
}
.course-info a {
  color: #dd1717;
  display: flex;
  font-weight: 600 !important;
  justify-content: end;
  text-decoration: none;
}
.course-btn-wrapper {
}
.course-btn-wrapper button {
  padding: 10px 20px;
  border: none;
  background-color: #dd1717;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .course-image img {
    display: none;
  }
  .course-card-section {
    padding: 20px 20px !important;
  }
  .course-title-text {
    padding: 50px 0 0px !important;
  }
  .course-image1 img, .course-image2 img, .course-image3 img{
    display: none !important;
  }
  .courseWrapper{
    padding: 20px !important;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 767px){
  .course-image img {
    display: none;
  }
  .courseWrapper{
    padding: 20px 10px !important;
    position: relative;
  }
  .course-card-section {
    padding: 20px 0px !important;
  }
  .course-title-text {
    padding: 0 !important;
  }
}

@media (max-width: 1200px) {
  .course-card-section {
    padding: 0px 10px 0 110px;
  }
  .courseWrapper{
    padding: 50px ;
  }
  .course-title-text {
    padding: 30px 20px 40px 250px;
}
}

@media (min-width: 1400px) and (max-width: 1920px){
  .course-title-text {
    padding: 30px 20px 40px 345px !important;
}
}