.digital-section {
    padding: 50px 0;
    z-index: 10;
    position: relative;
    background: #fff;
  }
  
  .digital-wrapper {
    padding: 50px 0;
  }
  
  .digital-inner-wrapper {
    padding: 0 25px;
  }
  
  .digital-text h5 {
    text-transform: uppercase;
    font-size: 13px;
    color: #676c71;
    padding-bottom: 10px;
    letter-spacing: 0.2px;
    font-weight: 600;
  }
  
  .digital-text h3 {
    font-size: 34px;
    font-weight: 600;
    color: #0e476b;
    letter-spacing: 0.2px;
    padding-bottom: 15px;
  }
  
  .digital-text h3 span {
    color: #dd1717;
  }
  
  .digital-text p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .digital-img-section {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .digital-img-section .digital-img1 {
    width: 550px;
    height: 500px !important;
    padding: 5px;
  }
  
  .digital-working-img {
    width: 100%;
    height: 500px !important;
    padding: 0 20px;
  }
  
  .digital-btn {
    padding: 20px 0;
  }
  
  .digital-btn button {
    background-color: #022d62;
    padding: 10px 20px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    border-radius: 3px;
  }
  
  .dm-que-wrapper {
    padding: 10px;
  }
  
  .dm-que-wrapper .main-que-service {
    border-bottom: 1px solid #676c71;
  }
  
  .dm-que-wrapper .que-icon-service {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }
  .dm-que-wrapper .que-icon-service p {
    font-weight: 600;
    margin: 0;
    padding-left: 10px;
    font-size: 22 px;
    color: #dd1717;
    letter-spacing: 0.2px;
    opacity: 0.9;
    cursor: pointer;
  }
  
  .sub-menu {
    display: none;
  }
  .sub-menu.is-open {
    display: block;
  }
  
  /* DM CARDS */
  
  .dm-card-section {
    padding: 15px;
  }
  
  .dm-main-card {
    background: linear-gradient(145deg, #ffffff, #ffffff);
    box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
    border: 1px solid #e2e8ec;
    border-radius: 10px;
    padding: 30px;
    display: grid;
    place-items: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }
  
  .dm-main-card .happy-cust-img {
    width: 90px;
    height: 108px;
  }
  
  .dm-main-card .expert-adv-img,
  .dm-main-card .project-comp-img {
    width: 100px;
    height: 100px;
  }
  
  .dm-main-card h3 {
    font-size: 34px;
    font-weight: 600;
    color: #022d62;
    padding-top: 20px;
    letter-spacing: 0.2px;
  }
  
  .dm-main-card p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    color: #676c71;
    letter-spacing: 0.2px;
    font-weight: 600;
  }
  
  .dm-about-text h5 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .dm-about-text h3 {
    font-size: 34px;
    font-weight: 600;
    color: #022d62;
    padding: 20px 0;
    letter-spacing: 0.2px;
  }
  
  .dm-about-text p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  .dm-about-col {
    padding: 20px 20px 20px 0;
  }
  
  .dm-about-col h5 {
    font-size: 20px;
    color: #022d62;
    letter-spacing: 0.2px;
    font-weight: 600;
  }
  
  .dm-about-col p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    color: #676c71;
    letter-spacing: 0.2px;
  }
  
  /* What we do */
  
  .dm-bus-heading {
    display: grid;
    place-items: center;
  }
  
  .dm-bus-heading h5 {
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #676c71;
    font-weight: 600;
  }
  
  .dm-bus-heading h3 {
    font-size: 34px;
    font-weight: 600;
    color: #0e476b;
    padding: 20px 0;
    width: 50%;
    text-align: center;
    letter-spacing: 0.2px;
  }
  
  .dm-bus-wrapper {
    background-color: #0e476b;
    border-radius: 20px;
    margin-top: 50px;
    padding: 50px;
    position: relative;
    height: 400px;
  }
  
  .dm-bus-list-Section {
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 20px;
    width: 30%;
    position: absolute;
    top: -40px;
    box-shadow: 0 0 40px 10px rgb(51 51 51/10%);
  }
  
  .dm-bus-list {
    padding: 10px;
    border-radius: 10px;
    background-color: #eeeef0;
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
    transition: 0.5s;
    color: #0e476b;
    font-weight: 600;
  }
  
  .dm-bus-list:hover {
    background-color: #dd1717;
    color: #fff;
  }
  
  .dm-bus-list p {
    margin: 0;
    font-size: 16px;
    padding-left: 10px;
    cursor: pointer;
    letter-spacing: 0.2px;
  }
  
  .dm-bus-list-details {
    width: 100%;
    display: grid;
    place-items: end;
  }
  
  .bus-details-wrapper {
    width: 65%;
  }
  
  .dm-list-details-wrapper {
    display: flex;
    width: 100%;
  }
  
  .dm-bus-details-wrapper {
    display: flex;
  }
  
  .bus-text {
    padding-right: 30px;
  }
  
  .bus-text h5 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #676c71;
    padding-bottom: 10px;
  }
  
  .bus-text h3 {
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    padding-bottom: 15px;
    letter-spacing: 0.2px;
  }
  
  .dm-bus-details-wrapper p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: justify;
    color: #fff;
    opacity: 0.9;
    margin: 0;
    letter-spacing: 0.2px;
  }
  
  .bus-img-wrapper {
    display: flex;
    align-items: center;
  }
  
  .dm-bus-details-wrapper button {
    background-color: #dd1717;
    padding: 10px 20px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    border-radius: 3px;
    display: flex;
    margin: 30px 0;
  }
  .dm-view {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: justify;
    color: #fff;
    margin: 0;
  }
  
  .dm-about-img {
    display: flex;
    justify-content: center;
  }
  
  .dm-dynamic-img {
    width: 500px;
  }
  
  /* media queries */
  @media (max-width: 576px) {
    .digital-section {
      padding: 0px;
    }
  
    .digital-wrapper {
      padding: 30px 10px;
    }
  
    .digital-text h3 {
      font-size: 25px;
    }
  
    .digital-img-section {
      display: none;
    }
  
    .dm-bus-heading h3 {
      width: 100%;
      font-size: 30px;
    }
    .dm-bus-wrapper {
      height: auto;
    }
    .dm-bus-details-wrapper {
      display: block;
    }
    .bus-details-wrapper {
      width: 100%;
    }
    .dm-bus-wrapper {
      padding: 20px;
    }
    .dm-bus-list-details {
      margin-top: 450px;
    }
    .dm-about-img {
      display: none;
    }
  
    .dm-bus-details-wrapper img {
      width: 200px;
    }
    .dm-bus-list-Section {
      padding: 30px 20px;
      width: 80%;
    }
    .dm-bus-list-Section {
      padding: 30px 20px;
      width: 100%;
      top: -40px;
      left: 0;
    }
    .digital-inner-wrapper {
      padding: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .digital-section {
      padding: 0px;
    }
  
    .digital-wrapper {
      padding: 30px 10px;
    }
  
    .digital-text h3 {
      font-size: 25px;
    }
  
    .digital-img-section {
      display: none;
    }
  
    .dm-bus-heading h3 {
      width: 100%;
      font-size: 30px;
    }
    .dm-bus-wrapper {
      height: auto;
    }
    .dm-bus-details-wrapper {
      display: block;
    }
    .bus-details-wrapper {
      width: 100%;
    }
    .dm-bus-wrapper {
      padding: 20px;
    }
    .dm-bus-list-details {
      margin-top: 450px;
    }
    .dm-about-img {
      display: none;
    }
  
    .dm-bus-details-wrapper img {
      width: 200px;
    }
    .dm-bus-list-Section {
      padding: 30px 20px;
      width: 80%;
    }
    .dm-bus-list-Section {
      padding: 30px 20px;
      width: 100%;
      top: -40px;
      left: 0;
    }
    .digital-inner-wrapper {
      padding: 0;
    }
  }
  @media (min-width: 992px) and (max-width: 1399px) {
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
  } 
  