.webdev-wrapper {
  position: relative;
  z-index: 10;
  background: #fff;
}

.wat-is-web-dev-wrapper {
  background: url("../Images/webdevbg.png");
  width: 100%;
  height: 100%;
  padding: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.wat-is-web-dev {
  background: #03060982;
  padding: 40px;
  padding: 50px 100px;
  /* top: -50%; */
  /* bottom: -20%; */
  top: 229px;
}
.wat-is-web-dev h3,
.wat-is-web-dev p {
  color: #fff;
}
.wat-webdev-do-wrapper {
  padding: 50px 0px;
}
.what-devv-ddo-img {
  width: 100%;
}

.what-is-web-dev-do-wrapper {
  background: #052543;
  padding: 0px 60px;
  display: flex;
  align-items: center;
  height: 450px;
}

.wat-is-web-dev-do h3,
.wat-is-web-dev-do p {
  color: #fff;
  padding-bottom: 25px;
  position: relative;
  width: fit-content;
}
.wat-is-web-dev-do h3::after {
  content: "";
  width: 50px;
  height: 3px;
  background: #79bd12;
  position: absolute;
  bottom: 33%;
  left: 0%;
  right: 0%;
  margin: 0 auto;
}
.web-dev-syllabus {
  padding-bottom: 50px;
}
.web-dev-heading {
  text-align: center;
  padding-bottom: 20px;
}
.syllabus-wrapper ul {
  display: flex;
  justify-content: space-evenly;
}
.syllabus-wrapper ul li {
  list-style: none;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.web-dev-syllabus-icon {
  padding-right: 10px;
  color: #79bd12;
}
.web-dev-heading p {
  color: #3c3c3c;
  letter-spacing: 0.5px;
  position: relative;
}
.web-dev-heading p::before {
  content: "";
  width: 50px;
  content: "";
  width: 50px;
  height: 1px;
  background: #79bd12;
  position: absolute;
  top: 12px;
  left: 37%;
}

.web-dev-heading p::after {
  content: "";
  width: 50px;
  content: "";
  width: 50px;
  height: 1px;
  background: #79bd12;
  position: absolute;
  top: 12px;
  right: 37%;
}
.webdev-hr-top {
  margin-bottom: 40px;
}
.webdev-hr-bottom {
  margin-top: 40px;
}

@media (min-width: 1400px) and (max-width: 1920px) {
  .what-devv-ddo-img {
    height: 480px;
  }
  .what-is-web-dev-do-wrapper {
    display: flex;
    align-items: center;
    height: 480px;
  }
}

@media (max-width: 1200px) {
  .web-dev-heading p::before {
    left: 36%;
  }
  .web-dev-heading p::after {
    right: 36%;
  }
  .wat-is-web-dev-do h3 {
    font-size: 30px;
  }
  .wat-is-web-dev-do {
    padding: 35px 30px 35px 35px;
  }
  .what-is-web-dev-do-wrapper {
    padding: 0px;
    height: auto;
}
.what-devv-ddo-img{
  height: 420px;
}
.what-is-web-dev-do-wrapper {
  height: 420px;
}
}


@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .wat-is-web-dev {
    padding: 0 !important;
  }
  .wat-is-web-dev-wrapper {
    padding: 50px 10px;
  }
  .what-is-web-dev-do-wrapper {
    height: auto;
    padding: 0;
  }
  .wat-is-web-dev-do h3::after{
    background: none;
  }
}
