.certificateSection {
  background-color: #041520;
  padding: 50px 0px;
  position: relative;
  z-index: 10;
}
.certificateWrapper {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  margin: 10px;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
}
.certificateHeading h3 {
  color: #fff;
  text-align: center;
}
.certificate {
  width: 250px;
  height: 200px;
}
