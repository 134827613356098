.webdev-service-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.dev-section {
  padding: 50px;
}

.dev-wrapper {
  position: relative;
  width: 100%;
}

.dev-wrapper-even {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
}

.dev-wrapper img {
  width: 60%;
  position: relative;
  border-radius: 5px;
}

.dev-wrapper-even img {
  width: 800px;
  position: relative;
  border-radius: 5px;
}

.dev-para-wrapper-odd {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.dev-para-wrapper-even {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.dev-para {
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  padding: 50px;
  border: 0.5px rgb(2 45 98/10%) solid;
  background-color: #fff;
  border-radius: 5px;
}

.dev-para h3 {
  font-size: 34px;
  font-weight: 600;
  color: #0e476b;
  padding-bottom: 15px;
}

.dev-para p {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: justify;
  color: #676c71;
  letter-spacing: 0.2px;
}

.devCard-solution {
  background: url("../../Images/Services/BG.jpg") no-repeat center center;
  height: 300px;
}

.devCard-wrapper {
  display: flex;
}


/* media queries */

@media (max-width: 576px) {
  .devCard-wrapper .marq-wrapper {
    display: none;
  }

  .dev-para-wrapper-odd {
    position: static;
    width: 100%;
  }

  .dev-para h3 {
    font-size: 25px;
  }

  .dev-para p {
    font-size: 13px;
  }

  .dev-para-wrapper-even {
    position: static;
    width: 100%;
  }

  .dev-section {
    padding: 10px;
  }

  .dev-wrapper img {
    width: 100%;
    height: auto;
  }

  .dev-wrapper-even img {
    width: 100%;
    height: auto;
  }

  .dev-wrapper-even {
    display: flex;
    flex-direction: column-reverse;
  }

  .dev-para {
    padding: 20px;
  }

  .devCard-left {
    padding: 20px;
  }
  .devCard-solution {
    height: auto;
  }
  .devCard-wrapper {
    height: 510px;
  }
  .devCard-heading-section h3 {
    font-size: 25px;
  }

  .devCard-left-wrapper h5 {
    font-size: 20px;
  }

  .devCard-left-wrapper p {
    font-size: 14px;
  }
  .devCard-left-wrapper {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .devCard-wrapper .marq-wrapper {
    display: none;
  }

  .dev-para-wrapper-odd {
    position: static;
    width: 100%;
  }

  .dev-para-wrapper-even {
    position: static;
    width: 100%;
  }

  .dev-section {
    padding: 10px;
  }

  
  .dev-wrapper img {
    width: 100%;
    height: auto;
  }

  .dev-wrapper-even img {
    width: 100%;
    height: auto;
  }

  .dev-wrapper-even {
    display: flex;
    flex-direction: column-reverse;
  }
  .dev-para {
    padding: 20px;
  }

  .devCard-left {
    padding: 20px;
    margin-top: 90px;
  }
  .devCard-solution {
    height: auto;
  }
  .devCard-wrapper {
    height: 500px;
  }
  .devCard-heading-section h3 {
    font-size: 30px;
  }

  .devCard-left-wrapper {
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1920px) {
} 