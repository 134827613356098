.companies {
  padding: 50px 0;
  position: relative;
  z-index: 10;
  background-color: #fff;
  /* height: 70vh; */
}

.companies-heading h3,
.companies-heading h5 {
  color: #0e476b;
  text-align: center;
}

.companies-section {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
}

.container-img {
  display: flex;
}

.container-img img {
  border: 2px solid rgba(0, 0, 0, 0.04);
  padding: 10px;
  width: 170px;
  height: 80px;
  margin: 0 5px;
}



/* .sliding-background img{
  border: 2px solid rgba(0, 0, 0, 0.04);
  padding: 10px;
  width: 170px;
  height: 80px;
  display: block;
  animation: slide 1.2s linear infinite;
}


@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
} */

