.webdesign-service-wrapper {
  position: relative;
  z-index: 10;
  background: #fff;
}
.design-section {
  padding: 50px;
  z-index: 10;
  background: #fff;
}

.design-wrapper {
  position: relative;
  width: 100%;
  z-index: 1000;
}


.web-service-banner {
  width: 100%;
  background: url("../../Images/Services/GraphicDesign/GraphicsDesignBanner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
}
.web-design-banner-text {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.web-design-banner-text h3 {
  color: #fff;
}


.design-para-wrapper-odd {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.design-para-wrapper-even {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.design-section {
  padding: 50px;
}

.design-wrapper {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.design-wrapper-even {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
}

.design-wrapper img {
  width: 700px;
  height: 400px;
  position: relative;
  border-radius: 5px;
}

.design-wrapper-even img {
  width: 700px;
  height: 400px;
  position: relative;
  border-radius: 5px;
}

.design-para-wrapper-odd {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.design-para-wrapper-even {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 60%;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.design-para {
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  padding: 50px;
  border: 0.5px rgb(2 45 98/10%) solid;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  position: relative;
}

.design-para .dot-1 {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  right: 0;
}

.design-para .dot-2 {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
}

.design-para h3 {
  font-size: 34px;
  font-weight: 600;
  color: #000249;
  padding-bottom: 15px;
  letter-spacing: 0.2px;
}

.design-para p {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: justify;
  color: #676c71;
  letter-spacing: 0.2px;
}


/* media queries */

@media (max-width: 576px) {
 
  .opt-wrapper .marq-wrapper {
    display: none;
  }

  .design-para-wrapper-odd {
    position: static;
    width: 100%;
  }

  .design-para-wrapper-even {
    position: static;
    width: 100%;
  }

  .design-section {
    padding: 10px;
  }

  .design-wrapper {
    display: block;
  }

  .design-wrapper-even {
    display: flex;
    flex-direction: column-reverse;
  }

  .design-wrapper img {
    width: 100%;
    height: auto;
  }

  .design-wrapper-even img {
    width: 100%;
    height: auto;
  }

  .design-para h3 {
    font-size: 25px;
  }

  .design-para {
    padding: 20px;
  }
  .design-para p {
    font-size: 13px;
  }

  .opt-left {
    padding: 20px;
  }
  .opt-wrapper {
    height: auto;
  }
  .opt-heading-section h3 {
    font-size: 30px;
  }

  .opt-left-wrapper {
    padding: 0;
  }
  .service-contact-wrapper, .service-contact-section {
    padding: 0px;
  }
  .service-contact-img-wrapper img {
    width: 300px;
    height: 400px;
}
}
@media (min-width: 768px) and (max-width: 991px) {
  .opt-wrapper .marq-wrapper {
    display: none;
  }
  .design-wrapper img {
    display: block;
  }

  .design-wrapper-even img {
    display: block;
  }
  .design-para-wrapper-odd {
    position: static;
    width: 100%;
  }

  .design-wrapper {
    display: block;
  }

  .design-wrapper-even {
    display: flex;
    flex-direction: column-reverse;
  }


  .design-para-wrapper-even {
    position: static;
    width: 100%;
  }
  .design-section {
    padding: 20px;
}
}
@media (min-width: 992px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .design-wrapper img {
    width: 1000px !important;
  }
  .design-wrapper-even img {
    width: 1000px !important;
  }
}
