.section-mobile-app {
  position: relative;
  z-index: 10;
  background: #fff;
}
.mobile-app-banner {
  width: 100%;
  height: 70vh;
  background-image: url("../../Images/MobileDev/MobileAppMainBanner.jpg");
  padding: 50px;
  display: flex;
  align-items: center;
}

.mb-banner-title {
  color: #fff;
  font-size: 50px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  /* font-family: 'Mate SC', serif; */
  font-family: "Buttershine ", serif;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  width: 50%;
}

/* Innovation */

.innovation {
  background-color: #fbfbfb;
}

.innovation-section {
  padding: 50px;
}

.innovation-heading h3 {
  color: #000249;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
  text-align: center;
}

.innovation-heading p {
  color: #616c71;
  font-size: 18px;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
  text-align: justify;
}

.innovation-outer-wrapper {
  /* padding-right: 50px; */
}

.innovation-mb {
  width: 100%;
  display: flex;
  justify-content: center;
}

.innovation-mb img {
  width: 250px;
  height: 450px;
}

.innovation-strip {
  position: relative;
  border-bottom: #eeeeee;
  box-shadow: 0 8px 10px -6px rgb(211, 211, 211);
  padding: 20px;
  display: flex;
  align-items: start;
  transition: 0.5s all ease;
}

.innovation-strip:hover {
  padding-left: 35px;
}

.innovation-strip p {
  color: #000249;
  font-size: 20px;
  font-weight: 600;
  width: 90%;
  /* text-align: end; */
  margin-bottom: 0;
  padding-left: 3px;
}

.innovation-card-outer-wrapper {
  padding: 15px;
}

.innovation-card-inner-wrapper {
  width: 100%;
  padding: 30px 15px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  transition: 0.5s all ease;
}

.innovation-card-inner-wrapper:hover {
  border-radius: 10px;
  box-shadow: 0 8px 10px 6px rgb(211, 211, 211);
}

.innovation-card-inner-wrapper .innovation-main-card {
  display: grid;
  justify-content: center;
}

.innovation-card-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.innovation-card-icon img {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.innovation-main-card p {
  margin-top: 10px;
  color: #000249;
  letter-spacing: 0.2px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
}

/* IOS Mobile  */
.ios-section {
  background-color: #000;
  padding: 100px 0;
}

.ios-inner-wrapper h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  line-height: 14px;
  margin-bottom: 20px;
  background: radial-gradient(
    circle farthest-corner at center center,
    #fd4949 0%,
    #97076d 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.ios-inner-wrapper h3 {
  font-size: 40px;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 20px;
}

.ios-inner-wrapper p {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #fff;
  padding-left: 5px;
}

.ios-inner-wrapper ul li {
  display: flex;
  align-items: start;
}

.ios-check-icon {
  color: #dd1717;
  border-radius: 50%;
  padding: 3px;
  margin-top: 3px;
}

.ios-list {
  font-size: 15px;
  letter-spacing: 0.8px;
  color: #fff;
  padding-left: 5px;
}

.ios-mb-img {
  width: 100%;
  display: flex;
  justify-content: end;
}

.ios-mb-img img {
  width: 500px;
}

/* Android Mobile  */
.android-section {
  background-color: #fff;
  padding: 100px 0;
}

.android-inner-wrapper h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  line-height: 14px;
  margin-bottom: 20px;
  background: radial-gradient(
    circle farthest-corner at center center,
    #fd4949 0%,
    #97076d 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.android-inner-wrapper h3 {
  font-size: 40px;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: 300;
  color: #000249;
  margin-bottom: 20px;
}

.android-inner-wrapper p {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #616c71;
  padding-left: 5px;
}

.android-inner-wrapper ul li {
  display: flex;
  align-items: start;
}

.android-check-icon {
  color: #dd1717;
  border-radius: 50%;
  padding: 3px;
  margin-top: 3px;
}

.android-list {
  font-size: 15px;
  letter-spacing: 0.8px;
  color: #616c71;
  padding-left: 5px;
}

.android-mb-img {
  width: 100%;
  display: flex;
  justify-content: start;
}

.android-mb-img img {
  width: 500px;
}

/* Start to end */

.start-to-end {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1.5)),
    url("../../Images/MobileDev/Mobile-banner.jpg");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  padding: 50px;
}

.start-to-end-heading h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}

.start-to-end-heading p {
  color: #fff;
  font-size: 18px;
  opacity: 0.9;
  letter-spacing: 0.2px;
}

.mobile-app-wrapper-spacing {
  padding: 0 20px;
}

.start-outer-wrapper {
  padding: 5px;
}

.start-inner-wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 5px rgba(58, 58, 58, 0.5);
}

.start-inner-wrapper h5 {
  color: #000249;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.start-inner-wrapper p {
  color: #616c71;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.mobility-design {
  padding-right: 80px;
}

.native-monitor {
  padding-left: 80px;
}

/* Process */

.process {
  padding: 50px 0;
}

.process-para h3 {
  font-size: 40px;
  color: #000249;
  font-weight: 600;
  padding-bottom: 10px;
  letter-spacing: 0.2px;
  text-align: center;
}
.process-para p {
  color: #616c71;
  letter-spacing: 0.2px;
  font-size: 18px;
  margin-bottom: 0;
}
.process-img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.process-img-wrapper img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.mobile-round-img-shadow {
}

.process-steps-outer-wrapper {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.process-steps-inner-wrapper h5 {
  font-size: 20px;
  color: #000249;
  font-weight: 600;
  padding-bottom: 10px;
  letter-spacing: 0.2px;
}

.process-steps-inner-wrapper p {
  color: #616c71;
  letter-spacing: 0.2px;
  font-size: 18px;
  margin-bottom: 0;
}

/* Media */
@media (max-width: 576px) {
  .mb-banner-title {
    font-size: 30px;
  }
  .android-mb-img img {
    width: 300px;
  }
  .process-img-wrapper img {
    display: none;
  }
  .innovation-heading h3,
  .process-para h3 {
    font-size: 25px;
  }
  .innovation-section {
    padding: 10px;
  }
  .ios-flex {
    flex-direction: column-reverse;
  }
  .ios-mb-img img {
    width: 300px;
  }
  .android-mb-img {
    display: flex;
    justify-content: center;
  }
  .start-to-end {
    padding: 10px;
  }
  .mobility-design {
    padding-right: 0px;
  }
  .native-monitor {
    padding-left: 0px;
  }
  .start-to-end-flex {
    flex-direction: column-reverse;
  }
  .start-to-end-heading h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-app-banner img {
    height: auto;
  }
  .android-mb-img img {
    width: 300px;
  }
  .process-img-wrapper img {
    display: none;
  }
  .innovation-heading h3,
  .process-para h3 {
    font-size: 25px;
  }
  .innovation-section {
    padding: 10px;
  }
  .ios-flex {
    flex-direction: column-reverse;
  }
  .ios-mb-img {
    display: flex;
    justify-content: center;
  }
  .ios-mb-img img {
    width: 300px;
  }
  .android-mb-img {
    display: flex;
    justify-content: center;
  }
  .start-to-end {
    padding: 10px;
  }
  .mobility-design {
    padding-right: 0px;
  }
  .native-monitor {
    padding-left: 0px;
  }
  .start-to-end-flex {
    flex-direction: column-reverse;
  }
  .start-to-end-heading h3 {
    font-size: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1920px) {
}
