.toolsSection {
    /* background-color: #0e476b; */
    padding: 50px 0px;
    background: #f1f8e7;
    z-index: 10;
    position: relative;
  }
  .toolsWrapper {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);   
    margin: 10px;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    background: #E4F1CF;
  }
  .toolsTitle h3{
    color: #0e476b;
  }
  .tools {
  }
  