.blogs {
  padding: 25px 0;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.blogs-heading h3,
.blogs-heading h5 {
  color: #0e476b;
  text-align: center;
}


.blogs-heading h5 {
  color: #6e6e6e;
  text-align: center;
}
.blogs-section {
  padding: 25px 0;
}

.blogs-outer-wrapper {
  padding: 15px;
}

.blogs-inner-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 0px 12.61px 0.39px rgba(0, 0, 0, 0.14);
}

.blogs-img-date-wrapper{
    position: relative;
}

.blogs-inner-wrapper .blogs-img img {
  width: 100%;
}

.blog-date-wrapper {
    position: absolute;
    bottom: -50px;
    left: 30px;
    background-color: #79bd12;
    padding: 20px;
    color: #fff;
    text-align: center;
    z-index: 10;
  }

  .blog-date-wrapper p{
    font-size: 15px;
  }

.blogs-para {
  padding: 32px;
  margin-top: 49px;
  height: 250px;
}

.blogs-para h4 {
  color: #0e476b;
}

.blogs-para p {
  color: #616c71;
  margin-bottom: 20px;
}

.blogs-para button {
  padding: 10px 15px;
  border: none;
  background-color: #0e476b;
  color: #fff;
  font-weight: 600;
}


.blog-info{
  color: #dd1717 !important;
  display: flex;
  font-weight: 600 !important;
  justify-content: end;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 20px 10px 0px;
}

/* Media Queries */
@media (max-width: 576px) {
/* .blogs-para {
  padding: 20px !important;
} */
/* .blogs-outer-wrapper {
  padding: 0;
} */
}

@media (max-width: 768px) {
  /* .blogs-para{
    height: 300px !important;
  } */
}


@media (max-width: 1200px) {

.blogs-para{
  height: 300px;
}
}