* {
  letter-spacing: 0.2px;
}
.viewBlogBody {
  background-color: #0c0f13;
  position: relative;
  z-index: 10;
}
.viewBlogSection {
  padding: 40px 0px;
  margin-bottom: 40px;
}
.viewBlogSection .blogImage img {
  width: 100%;
  height: auto;
  border-radius: 7px;
}
.viewBlogSection .view-blog-profile {
  border-bottom: none;
}
.viewBlogSection .view-blog-profile ul {
  display: flex;
  margin-bottom: 0px;
  padding-left: 20px;
}
.viewBlogSection .view-blog-profile ul li {
  margin-right: 40px;
  color: #fff;
  list-style: none;
}
.viewBlogSection .blogTitle h3 {
  font-size: 40px;
  text-transform: capitalize;
}
.viewBlogSection .blogTitle h3,
.viewBlogSection .blogDescription p,
.recentStory h3,
.categories ul li,
.recentStory h3,
.Tags ul li {
  /* color: #171818; */
  color: #fff;
  list-style: none;
}
.categories ul {
  padding-left: 0px !important;
}
.recentStorySection {
  margin: 0px 40px;
}
.recentStoryList {
  display: flex;
  padding-bottom: 20px;
}
.recentStory {
  border-bottom: 1px solid;
  margin: 20px 0px;
}

.recentStoryList .blogImage img {
  width: 100px;
  height: unset !important;
}
.recentBlogInfo {
  padding: 0px 20px;
}
.recentBlogInfo p {
  margin-bottom: 0px;
  color: #fff;
}
.tagsList div {
  padding: 10px 0;
}
.tagsList a {
  margin-right: 10px;
  color: #fff !important;
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  border-radius: 20px;
  padding: 5px 10px;
}

.blog-bg-banner {
  background: linear-gradient(rgba(133, 130, 145, 0.2), rgba(0, 0, 0, 1.5)),
    url("../Images/Blog/BlogBanner.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-blogText h3 {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
  font-size: 40px;
}
.view-blogText h5 {
  color: #fff;
  opacity: 0.9;
  text-align: center;
  letter-spacing: 0.2px;
  font-size: 25px;
}
.view-blog-profile {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #521d1d;
}
