.scetionWhatIsDm {
  padding-top: 25px;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.whatIsDm {
  padding: 10px;
}
.whatIsDm h3 {
  color: #0e476b;
}
.whatIsDm p {
  padding: 10px 0px;
  color: #3c3c3c;
}
.whatIsGdImgWrapper {
  padding: 10px;
  position: relative;
}
.whatIsGdImg {
  width: 100%;
}
.whatIsGdImg-absolute-bg {
  position: absolute;
  background: #cedae1;
  width: 300px;
  height: 300px;
  z-index: -1;
  left: -30%;
  top: 170px;
  border-radius: 20px;
}


@media (max-width: 576px) {
.whatIsDm h3 {
  font-size: 30px;
}
}

@media (max-width: 768px) {
  .BestGdImage img {
    height: auto;
    max-width: 100%;
    /* width: 100%; */
  }
  .whatIsGdImg-absolute-bg {
    position: absolute;
    background: #ffeded;
    width: 300px;
    height: 300px;
    z-index: -1;
    right: 0 !important;
    left: unset !important;
    top: 60% !important;
    border-radius: 20px;
  }
  .GdMgview {
    display: flex;
    flex-direction: column-reverse;
  }
}
