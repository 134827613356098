
.sectionCareerPath{
    padding: 50px;
    position: relative;
    z-index: 10;
    background: #fff;
}
.eligibility-gd h3, .carrerPath h3,.skillGain h3{
    font-size: 25px;
    color: #0e476b;
}

.eligibilitylist-gd{
    padding-left: 0px !important;
}
.eligibilitylist-gd li {
    list-style: none;
    display: flex;
    align-items: center;
  }

  .eligibilitylist-gd p {
    padding: 0px 10px;
    color: #3c3c3c;
  }
  
  .eligibilitylist-gd .checkIcon-gd,.carrerPath .checkIcon-gd,.skillGain .checkIcon-gd {
    color: #79bd12;
  }

  .carrerPath , .skillGain, .eligibility-gd{
    box-shadow: 0px 0px 10px 0px #e6e4e4;
    padding: 20px;
    margin: 0px 20px;
  }




  /* Media Queries */
@media (max-width: 576px) {
  .sectionCareerPath{
    padding: 0;
  }
  .carrerPath , .skillGain, .eligibility-gd{
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .carrerPath , .skillGain, .eligibility-gd{
    margin: 10px 0;
  }
}