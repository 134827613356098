.sectionBestGd {
  padding-top: 50px;
  padding-bottom: 25px;
  background-color: #fff;
  position: relative;
  z-index: 10;
}

.BestGdImage {
  display: flex;
  /* justify-content: end; */
  position: relative;
}
.BestGdImage img {
  height: auto;
  max-width: 100%;
}
.GdImg1 {
}
.GdImg2 {
  position: absolute;
  top: 50px;
  left: 62%;
}
.BestGdDescription {
  padding: 0px 20px;
}
.BestGdTitle h3 {
  font-size: 35px !important;
  color: #0e476b;
}
.BestGdTitle p {
  padding: 10px 0px;
  color: #3c3c3c;
}
.CourseDescription p {
  font-size: 16px !important;
  color: #3c3c3c;
  padding: 0px 10px;
}
.GdIcon {
  display: flex;
  align-items: center;
  padding: 2px 0px;
}

.gdCheckIcon {
  color: #79bd12;
}
.CourseDescription-bottom {
  padding-top: 10px;
}

.BestGdImage .GdImg2 {
  animation-name: UpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes UpDown {
  50% {
    transform: translateY(20px);
  }
}

@media (max-width: 768px) {
  .sectionBestGd {
    padding-bottom: 0px !important;
  }
  .BestGdImage {
    padding-top: 50px;
  }
  .GdImg2 {
    position: absolute;
    top: 95px;
    left: 62%;
  }
  .BestGdDescription {
    padding: 30px 10px 0px 10px;
  }
}

/* Media Queries */
@media (max-width: 576px) {
  .GdImg2 {
    display: none;
  }
  .BestGdImage {
    padding-top: 10px;
  }
  .sectionBestGd {
    padding-top: 25px;
  }
}
