.best-dm-section{
  padding: 50px 0px;
  z-index: 10;
  background-color: #fff;
  position: relative;
}
.bestDmCrsSection {
  padding-top: 20px;
}
.bestDmCrsImageWrapper {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 100%;
}
.bestDmCrsImageWrapper img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
.bestDmCrsImageWrapper:hover img {
  transform: scale(1.1);
}

.BestDmCrsSection h3 {
  color: #0e476b;
}
.bestDmCrsWrapper {
  margin-right: 50px;
}
.bestDmCrsWrapper p {
  padding-top: 10px;
  color: #3c3c3c;
}
.bestDmCrsWrapper p a{
text-decoration: none;
color: #0e476b;
}
.bestDmCrsWrapper p a:hover{
  color: #0e476b;
}