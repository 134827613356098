/* Get in touch */
.get-in-touch-modal {
  z-index: 9999;
  /* top: 10%; */
  background-color: rgba(0, 0, 0, 0.3);
  --bs-modal-width: 700px;
}
.get-in-touch-modal-header {
  border-bottom: none;
}

.get-in-touch-modal-dialog {
  display: flex;
  align-items: center;
  height: 90vh;
}
.get-in-touch-modal-header {
  border-bottom: none;
  padding: 0;
}
.get-in-touch-modal-body {
  padding: 0;
}
.get-in-touch-img-wrapper {
  width: 120px;
  height: 100%;
  background-color: #79bd12;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-left: 172px;
}
.get-in-touch-img-wrapper img {
  width: auto;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: 20px;
}
.get-in-touch-btn-close {
  border: none;
}
.get-in-touch-btn-close-icon {
  position: absolute;
  /* top: -13px; */
  top: -8px;
  right: -5px;
  background-color: #0e476b;
  opacity: 0.9;
  color: #fff !important;
  padding: 2px !important;
  border: none;
  border-radius: 50% !important;
  z-index: 100;
}
.get-in-touch-section {
  padding: 20px 10px !important;
}

.get-in-touch-modal-title {
  font-size: 30px;
  font-size: 25px;
  color: #0e476b;
  font-weight: 700;
  text-align: center;
}
.get-in-touch-modal-text {
  padding-bottom: 20px;
  color: #79bd12;
  margin: 0;
  text-align: center;
}
.get-in-touch-btn-wrapper {
  display: flex;
  justify-content: center;
}
/* .get-in-touch-btn {
  background-color: #0e476b;
  padding: 5px 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.get-in-touch-btn:hover {
  background-color: #0e476b;
  color: #fff;
} */


  
/* Media Queries */
@media (max-width: 576px) {
  .get-in-touch-img-wrapper img{
    display: none;
  }
}