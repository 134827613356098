.guidance-model {
    z-index: 9999;
    /* top: 10%; */
    background-color: rgba(0, 0, 0, 0.3);
    --bs-modal-width: 700px;
  }
  .guidance-modal-dialog {
    display: flex;
    align-items: center;
    height: 90vh;
  }
  .guidance-modal-header {
    border-bottom: none;
    padding: 0;
  }
  .guidance-modal-body {
    padding: 0;
  }
  .get-career-guidance-img-wrapper {
    width: 100px;
    height: 100%;
    background-color: #0e476b;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .get-career-guidance-img-wrapper img {
    width: 250px;
    /* height: 400px; */
    position: absolute;
    bottom: 0;
    left: 20px;
    z-index: 100;
  }
  .guidance-btn-close {
    border: none;
  }
  .guidance-btn-close-icon {
    position: absolute;
    top: -13px;
    right: -11px;
    background-color: #79bd12;
    opacity: 0.9;
    color: #fff !important;
    padding: 2px !important;
    border: none;
    z-index: 100;
    border-radius: 50% !important;
  }
  .get-career-guidance-section {
    padding: 20px 10px !important;
  }
  
  .get-career-guidance-modal-title {
    font-size: 30px;
    font-size: 25px;
    color: #0e476b;
    font-weight: 700;
    text-align: center;
  }
  .get-career-guidance-modal-text {
    padding-bottom: 20px;
    color: #79bd12;
    margin: 0;
    text-align: center;
  }
  .guidance-btn-wrapper{
    display: flex;
    justify-content: center;
  }
  /* .guidance-btn{
    background-color: #79bd12;
    padding: 5px 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .guidance-btn:hover{
    background-color: #79bd12;
    color: #fff;
  } */


  
/* Media Queries */
@media (max-width: 576px) {
  .get-career-guidance-img-wrapper img{
    display: none;
  }
}