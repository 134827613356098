.reg-modal-content {
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 18%;
  z-index: 9999;
}

.reg-modal-body {
  display: block;
}

.reg-title {
  color: #0e476b;
}

.reg-form-control {
  padding: 6px 10px;
  border: 0.5px solid #e2eef2;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  color: #616c71;
}
.reg-form-control:focus {
  outline: none;
}

input {
  padding: 0;
}

.reg-modal-content .modal-content {
  width: 80%;
}
.reg-modal-content .modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}
.reg-pop-inputWrapper select {
  padding: 10px;
  margin-bottom: 10px;
}

.btn-enquire {
  background: #79bd12;
  color: #fff;
  padding: 10px;
}
.btn-enquire:hover {
  background: #79bd12;
  color: #fff;
}

.enquire-btn-wrapper {
  display: flex;
  justify-content: center;
}

/* MEDIA QUERY */
@media (max-width: 576px) {
  .reg-modal-content {
    width: 100%;
    overflow-y: scroll;
    padding-left: 0 !important;
  }
  .reg-modal-content .modal-content {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .reg-modal-content .modal-content{
    width: 100%;
  }
  .reg-modal-content .modal.show .modal-dialog {
    padding: 0px 17px;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .reg-modal-content {
    top: 25% !important;
  }
}
