.video {
  padding: 50px 0;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.video-section {
  border: 1px solid #dd1717;
  padding: 10px;
}

.video-wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.5), #000),
    url("../Images/bgRequestcallBack.jpg") local center center;
  width: 100%;
  height: 500px;
  background-size: cover;
  position: relative;
}

.video-heading h3 {
  color: #fff;
  position: absolute;
  bottom: 12%;
  z-index: 8;
  text-align: center;
  width: 100%;
}

.video-play-wrapper {
  width: 100%;
  position: absolute;
  bottom: 50%;  
  text-align: center;
  z-index: 8;
 
}

.video-play-btn {
  color: #fff;
  background-color: #dd1717;
  border-radius: 50%;
  padding: 5px;
  animation: video-play-pulse 1.5s infinite;
}

@keyframes video-play-pulse {
  0% {
    box-shadow: 0 0 0 0 #dd1717;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

