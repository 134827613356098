.FaqsSetion {
  padding: 50px 0px;
  background: #fff;
  position: relative;
  z-index: 10;
}
.FaqsSetion .accordion-button:focus {
  z-index: unset !important;
  border-color: none !important;
  outline: 0;
  box-shadow: unset !important;
}
.FaqTitle h3 {
  color: #0e476b;
}
.FaqsSetion .accordion-body p {
  font-size: 16px;
}
.faqDm-img {
  width: 100%;
  margin-left: 20px;
}
.FaqsSetion .accordion-button {
  padding: 5px;
}
