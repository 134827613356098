.faqSection {
  background: #e6ecf0;
  padding: 50px 0px;
  position: relative;
  z-index: 10;
}
.fqaGdTitle h3 {
  color: #0e476b;
  padding-bottom: 20px;
}
.faq-text h5 {
  color: black;
}
.faq-text p {
  font-size: 18px;
}
.faq-text {
  padding: 40px;
}
.main-faq-bg p {
  color: #3c3c3c;
}
.faqImgWrapper {
  display: flex;
  justify-content: center;
}
.faqQueInGd {
  width: 300px;
  height: 365px;
}

.faqsection .accordion-button:focus {
  z-index: unset !important;
  border-color: none !important;
  outline: 0;
  box-shadow: unset !important;
}
.faqsection .accordion-body p {
  font-size: 16px;
}
.faqDm-img {
  width: 100%;
  margin-left: 20px;
}
.faqsection .accordion-button {
  padding: 5px;
}
