.dmSyllabussection {
    padding: 50px 0px;
    background: url("../Images/Digitalmarketing/syllabus/syllabus_bg.jpg");
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 10;
  }
  .syllabus-details {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    margin: 20px;
    background-color: #fff;
  }
  
  .SyllabusTitle h3 {
    color: #0e476b;
    font-size: 35px;
  }
  
  /*  */
  .syllabus-details .chapterName h5 {
    background-color: #efefef;
    padding: 20px;
    color: #0e476b;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 20px;
  }
  .dmSyllabusContent {
    position: relative;
  }
  
  .chapterDetails-list {
    padding: 5px 15px 10px 15px;
  }
  .chapterDetailsWrapper {
    padding: 20px;
  }
  
  .chapterDetails-list li {
    list-style: none;
    border-bottom: 1px solid #efefef;
    padding: 7px 0px;
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
  }
  .chapterDetails-list li p {
    font-size: 15px;
  }
  
  .syllabus-details .chapterName ul {
    padding: 20px;
  }
  .bullets {
    display: flex;
    align-items: start;
  }
  .badgeIcon {
    color: #dd1717;
  }
  