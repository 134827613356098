.our-work-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
  padding: 25px 0px;
}
.col-our-works {
  position: relative;
  padding: 20px 0px !important;
}
.our-works-points {
  border-radius: 50%;
  padding: 20px;
  background: rgb(121, 189, 18, 0.5);
  width: 300px;
  height: 300px;
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
  0 7px 18px rgba(0, 9, 128, 0.05);
}
.our-work-heading  h3{
    text-align: center;
}
.our-work-heading p {
    padding: 10px 0px;
    text-align: center;
}
.subdiv-title h5 {
  font-size: 20px;
  color: #0e476b;
  font-weight: 700;
}

.subdiv-content p {
  color: #3c3c3c;
}
.subdiv-heading1 {
  width: 261px;
  height: 100px;
  border: solid 1px #f3f3f3;
  border-color: transparent transparent #f3f3f3 transparent;
  border-radius: 0 0 257px 83%/60px;
  position: absolute;
  top: -31px;
  left: 20px;
}

.subdiv-heading2 {
  width: 308px;
  height: 100px;
  border: solid 1px #f3f3f3;
  border-color: transparent transparent #f3f3f3 transparent;
  border-radius: 0 0 257px 83%/60px;
  position: absolute;
  top: 4px;
  left: -4px;
}

.subdiv-title {
  position: absolute;
  bottom: 69%;
  left: 91px;
}
.subdiv-content p{
    position: absolute;
    /* top: 0; */
    bottom: 24%;
    width: 73%;
    left: 30px;
    padding: 10px;
}