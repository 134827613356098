.main-contactus-page {
  color: #111111;
  background-color: #f3f3f3;
  min-height: 750px;
  position: relative;
  z-index: 10;
  padding: 25px 0px;
}

.main-contactus-inner {
  color: #ffffff;
  background-color: #0e476b;
  height: 500px;
  margin-bottom: 0;
  position: relative;
  top: 90px;
}
.main-contactform {
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -50px;
  padding: 30px 30px;
}
.contact-input-class {
  margin-bottom: 5px;
}
.main-contactform label {
  color: black;
  font-weight: 600 !important;
  padding: 5px 0px;
}
.main-contactus-page .form-control:active,
.main-contactus-page .form-control:focus,
.main-contactus-page .form-select:active,
.main-contactus-page .form-select:focus {
  border-color: none;
}
.main-contactus-page .form-control:hover,
.main-contactus-page .form-control:active,
.main-contactus-page .form-control:focus,
.main-contactus-page .form-select:active,
.main-contactus-page .form-select:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
textarea.form-control {
  height: auto;
}
.main-contactform .contact-btn-submit-wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
}

.main-contact-detail {
  padding: 65px 0px;
  align-items: center;
}
.main-contact-detail ul li {
  list-style-type: none;
  padding: 15px;
}
.main-contact-detail h5 {
  text-align: left;
  padding: 0px 20px;
}
.contact-call-us {
  display: flex;
  color: white;
}
/*btn css */

.contact-btn-submit a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;
}
.contact-btn-submit a::before {
  position: absolute;
  top: 110px;
  left: -50px;
  right: -50px;
  height: 170px;
  content: "";
  background: #79bd12;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  border-radius: 50%;
  transition-duration: 800ms;
  z-index: 1;
  color: #ffffff;
}
.contact-btn-submit a::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  opacity: 1;
  transform: scaleX(1);
  transition-duration: 800ms;
  z-index: -1;
  color: #ffffff;
}

.contact-btn-submit a::after {
  background-color: #79bd12;
}

.contact-btn-submit a::before {
  background-color: #0e476b;
}

.contact-btn-submit a:hover::before {
  top: 0%;
  left: -70px;
  right: -70px;
}

.contact-btn-submit a:hover::after {
  transform: scaleX(0);
  transition-duration: 1500ms;
}

.contact-btn-submit:hover .contact-btn-submit a {
  color: #fff;
}
.contact-btn-submit .btn-text {
  position: relative;
  z-index: 1;
  color: #fff;
}

/* rotatee border css */
.animated-button1 {
  padding: 10px 40px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  background: #79bd12;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
}

.animated-button1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #fff);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #fff);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #fff);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(43, 8, 8, 0)),
    to(#79bd12)
  );
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #fff);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
/* rotatee border css end */

/* Media Queries */
@media (max-width: 576px) {
  .main-contactform {
    padding: 10px !important;
  }

  .main-contactus-inner {
    height: auto;
    top: 90px;
  }
  .main-contactus-inner {
    margin-bottom: 90px;
  }
}
