.satisfaction-guarantee {
  padding-top: 25px;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.guarantee-heading h3,
.guarantee-heading h5 {
  color: #0e476b;
  text-align: center;
}
.guarantee-heading h5 {
  color: #6e6e6e;
  text-align: center;
}
.gurantee-outer-wrapper h5 {
  color: #0e476b;
  font-size: 22px;
  font-weight: 700;
}
.guarantee-section {
  padding-top: 50px;
}

.gurantee-outer-wrapper {
  padding: 10px;
}

.guarantee-img-wrapper {
  width: 100%;
}

.guarantee-img-wrapper img {
  width: 200px;
}

/* Bottom-right */

.guarantee-inner-wrapper-br {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  padding: 50px 50px 50px;
  padding-right: 280px;
  min-height: 350px;
  transition: all 300ms linear;
  z-index: 2;
}

.guarantee-inner-wrapper-br::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #79bd12;
  transform: scale(0);
  transform-origin: right top;
  border-radius: 500px 0 0;
  z-index: -1;
  content: "";
  transition: all 300ms linear;
}
.guarantee-inner-wrapper-br:hover:after {
  transform: scale(1.5);
  transform-origin: right top;
  border-radius: 0 0 0 500px;
}

.guarantee-inner-wrapper-br:hover .gurantee-icon-br {
  transform: rotate(180deg) scale(-1);
}
.guarantee-image-box-br {
  position: absolute;
  right: 0;
  top: 0;
}
.guarantee-image-box-br .gurantee-icon-br {
  position: absolute;
  right: 115px;
  bottom: -5px;
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2;
  transition: all 300ms ease;
  border: 2px solid #79bd12;
}

.guarantee-image-box-br .guarantee-img-br {
  border-radius: 0 0 0 400px;
  overflow: hidden;
  max-width: 280px;
}

.guarantee-image-box-br .guarantee-img-br img {
  width: 100%;
  transition: all 300ms ease;
}

.guarantee-inner-wrapper-br .guarantee-content-br {
  position: relative;
}

.guarantee-inner-wrapper-br .guarantee-content-br p {
  color: #3c3c3c;
}

.guarantee-inner-wrapper-br:hover .guarantee-content-tr p {
  color: #fff;
}

.guarantee-inner-wrapper-br:hover .guarantee-content-tr h5 {
  color: #fff;
  font-weight: 800;
}
/* Bottom-left */

.guarantee-inner-wrapper-bl {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  padding: 50px 50px 50px;
  padding-left: 280px;
  padding-right: 50px;
  min-height: 350px;
  transition: all 300ms linear;
  z-index: 2;
}
.guarantee-inner-wrapper-bl::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #79bd12;
  transform: scale(0);
  transform-origin: left bottom;
  border-radius: 0 500px 0 0px;
  z-index: -1;
  content: "";
  transition: all 300ms linear;
}
.guarantee-inner-wrapper-bl:hover:after {
  transform: scale(1.5);
  transform-origin: left bottom;
  border-radius: 0 500px 0 0px;
}

.guarantee-inner-wrapper-bl:hover .gurantee-icon-bl {
  transform: rotate(180deg) scale(-1);
}
.guarantee-image-box-bl {
  position: absolute;
  left: 0;
  bottom: 0;
}

.guarantee-image-box-bl .gurantee-icon-bl {
  position: absolute;
  right: 75px;
  top: -10px;
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #79bd12;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2;
  transition: all 300ms ease;
}

.guarantee-image-box-bl .guarantee-img-bl {
  border-radius: 0 400px 0 0;
  overflow: hidden;
  max-width: 280px;
}

.guarantee-image-box-bl .guarantee-img-bl img {
  width: 100%;
  transition: all 300ms ease;
}

.guarantee-inner-wrapper-bl .guarantee-content-bl {
  position: relative;
}

.guarantee-inner-wrapper-bl .guarantee-content-bl p {
  color: #3c3c3c;
}
.guarantee-inner-wrapper-bl:hover .guarantee-content-bl p {
  color: #fff;
}
.guarantee-inner-wrapper-bl:hover .guarantee-content-bl h5 {
  color: #fff;
  font-weight: 800;
}
/* Top-right */

.guarantee-inner-wrapper-tr {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  padding: 50px 50px 50px;
  padding-right: 280px;
  min-height: 350px;
  transition: all 300ms linear;
  z-index: 2;
}

.guarantee-inner-wrapper-tr::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #79bd12;
  transform: scale(0);
  transform-origin: right bottom;
  border-radius: 500px 0 0;
  z-index: -1;
  content: "";
  transition: all 300ms linear;
}
.guarantee-inner-wrapper-tr:hover:after {
  transform: scale(1.5);
  transform-origin: right bottom;
  border-radius: 500px 0 0;
}
.guarantee-inner-wrapper-tr:hover .gurantee-icon-tr {
  transform: rotate(180deg) scale(-1);
}
.gurantee-image-box-tr {
  position: absolute;
  right: 0;
  bottom: 0;
}
.gurantee-image-box-tr .gurantee-icon-tr {
  position: absolute;
  right: 115px;
  top: -12px;
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #79bd12;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2;
  transition: all 300ms ease;
}

.gurantee-image-box-tr .guarantee-img-tr {
  border-radius: 400px 0 0 0px;
  overflow: hidden;
  max-width: 280px;
}

.gurantee-image-box-tr .guarantee-img-tr img {
  width: 100%;
  transition: all 300ms ease;
}

.guarantee-inner-wrapper-tr .guarantee-content-tr {
  position: relative;
}

.guarantee-inner-wrapper-tr .guarantee-content-tr p {
  color: #3c3c3c;
}

.guarantee-inner-wrapper-tr:hover .guarantee-content-br p {
  color: #fff;
}
.guarantee-inner-wrapper-tr:hover .guarantee-content-br h5 {
  color: #fff;
  font-weight: 800;
}
/* Top-left */

.guarantee-inner-wrapper-tl {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  padding: 50px 50px 50px;
  padding-left: 280px;
  padding-right: 50px;
  min-height: 350px;
  transition: all 300ms linear;
  z-index: 2;
}
.guarantee-inner-wrapper-tl::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #79bd12;
  transform: scale(0);
  transform-origin: left top;
  border-radius: 0 0 500px 0;
  z-index: -1;
  content: "";
  transition: all 300ms linear;
}
.guarantee-inner-wrapper-tl:hover:after {
  transform: scale(1.5);
  transform-origin: left top;
  border-radius: 0 0 500px 0;
}
.guarantee-inner-wrapper-tl:hover .gurantee-icon-tl {
  transform: rotate(180deg) scale(-1);
}

.guarantee-image-box-tl {
  position: absolute;
  left: 0;
  top: 0;
}

.guarantee-image-box-tl .gurantee-icon-tl {
  position: absolute;
  right: 75px;
  bottom: -10px;
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #79bd12;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2;
  transition: all 300ms ease;
}

.guarantee-image-box-tl .guarantee-img-tl {
  border-radius: 0 0 400px 0;
  overflow: hidden;
  max-width: 280px;
}

.guarantee-image-box-tl .guarantee-img-tl img {
  width: 100%;
  transition: all 300ms ease;
}

.guarantee-inner-wrapper-tl .guarantee-content-tl {
  position: relative;
}

.guarantee-inner-wrapper-tl .guarantee-content-tl p {
  color: #3c3c3c;
}
.guarantee-inner-wrapper-tl:hover .guarantee-content-tl p {
  color: #fff;
}
.guarantee-inner-wrapper-tl:hover .guarantee-content-tl h5 {
  color: #fff;
  font-weight: 800;
}

/* Media Queries */
@media (max-width: 576px) {
  .guarantee-inner-wrapper-tr {
    padding: 20px;
    padding-bottom: 300px;
  }
  .guarantee-inner-wrapper-br {
    padding: 20px;
    padding-top: 300px;
  }
  .guarantee-inner-wrapper-bl {
    padding: 20px;
    padding-bottom: 300px;
  }
  .guarantee-inner-wrapper-tl {
    padding: 20px;
    padding-top: 300px;
  }
  .guarantee-inner-wrapper-tr,
  .guarantee-inner-wrapper-br {
    padding: 20px;
  }
  .guarantee-inner-wrapper-tr,
  .guarantee-inner-wrapper-tl,
  .guarantee-inner-wrapper-br,
  .guarantee-inner-wrapper-bl {
    height: 500px;
  }
  .gurantee-image-box-tr,
  .guarantee-image-box-bl,
  .guarantee-image-box-br,
  .guarantee-image-box-tl {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .gurantee-image-box-tr .guarantee-img-tr,
  .guarantee-image-box-bl .guarantee-img-bl,
  .guarantee-image-box-br .guarantee-img-br,
  .guarantee-image-box-tl .guarantee-img-tl {
    overflow: visible;
  }
  .guarantee-inner-wrapper-bl,.guarantee-inner-wrapper-tl {
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
  .gurantee-image-box-tr .gurantee-icon-tr,
  .guarantee-image-box-bl .gurantee-icon-bl,
  .guarantee-image-box-br .gurantee-icon-br,
    .guarantee-image-box-tl .gurantee-icon-tl {
    bottom: -40px !important;
    top: auto;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .guarantee-content-br,
  .guarantee-content-bl,
  .guarantee-content-tr, 
  .guarantee-content-tl {
    margin-top: 50px;
  }
  .guarantee-inner-wrapper-tr .guarantee-content-br h5 {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .guarantee-inner-wrapper-tr,
  .guarantee-inner-wrapper-br {
    padding: 20px;
  }
  .guarantee-inner-wrapper-tr,
  .guarantee-inner-wrapper-tl,
  .guarantee-inner-wrapper-br,
  .guarantee-inner-wrapper-bl {
    height: 500px;
  }
  .gurantee-image-box-tr,
  .guarantee-image-box-bl,
  .guarantee-image-box-br,
  .guarantee-image-box-tl {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .gurantee-image-box-tr .guarantee-img-tr,
  .guarantee-image-box-bl .guarantee-img-bl,
  .guarantee-image-box-br .guarantee-img-br,
  .guarantee-image-box-tl .guarantee-img-tl {
    overflow: visible;
  }
  .guarantee-inner-wrapper-bl,.guarantee-inner-wrapper-tl {
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
  .gurantee-image-box-tr .gurantee-icon-tr,
  .guarantee-image-box-bl .gurantee-icon-bl,
  .guarantee-image-box-br .gurantee-icon-br,
    .guarantee-image-box-tl .gurantee-icon-tl {
    bottom: -40px !important;
    top: auto;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .guarantee-content-br,
  .guarantee-content-bl,
  .guarantee-content-tr, 
  .guarantee-content-tl {
    margin-top: 50px;
  }
  .guarantee-inner-wrapper-tr .guarantee-content-br h5 {
    text-align: center;
  }
}
