.DMCourseListSection {
  background: #0e476b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
  position: relative;
  z-index: 10;
}
.DMCourseListingWrapper {
  padding: 20px 0px;
}

.courseImg {
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
}
.DMCourseListTitle h3 {
  text-align: center;
  color: #fff;
  font-size: 35px;
  opacity: 0.9;
}
.DMCourseListing {
  display: flex;
  justify-content: center;
}
.DMCourseListing ul li {
  color: #fff;
  padding: 10px 5px;
  font-size: 22px;
  list-style-type: none;
}
.DMCourseListingBottom-li {
  display: flex;
  justify-content: center;
  color: #fff;
}
.courseRight ul li {
  text-align: right;
  margin-left: -120px;
}
.courseImgWrapper {
  margin: 0px 20px;
}
.DMCourseListing-content {
  padding-top: 30px;
}
.courseRight ul,
.DMCourseListing-content ul {
  padding-left: 0px !important;
}




/* Media Queries */
@media (max-width: 576px) {
  .DMCourseListing {
    display: block;
  }
  .courseRight ul li {
    text-align: left;
    margin: 0;
}
.faqDm-img{
  margin-left: 0 !important;
}
.certificateWrapper{
  margin: 0 !important;
  padding: 5px 0 !important;
}
.courseImg{
  width: 100%;
}
.bestDmCrsWrapper{
  margin-right: 0;
}
.DMCourseListTitle h3{
  font-size: 30px;
}
.DMCourseListing ul li{
  font-size: 18px;
  padding: 5px !important;
}
.DMCourseListing-content{
  padding-top: 0;
}
}

@media (max-width: 768px) {
  .DMCourseListing {
    display: block;
  }
  .courseRight ul li, .DMCourseListing ul li {
    text-align: center;
    margin-left: 0;
    font-size: 22px !important;
  }
  .courseImgWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1200px){
  .DMCourseListing ul li{
    font-size: 16px;
  }
  .courseImg{
    width: 250px;
  }
}