.digitalMarketing {
  padding: 50px 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../Images/Digitalmarketing/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 10;
}
.digitalMarketing-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.digitalMarketingHeading h3 {
  color: #fff;
  padding-bottom: 20px;
  font-size: 35px;
}
.digitalMarketing-top-Text p{
  color: #fff;
}

.DMWrapper,
.DMPointsSubtitile {
  position: relative;
}
.DMWrapper .DMPointsSubtitile {
  display: flex;
}
.DMIcon {
  color: #ffd300;
}
.DMWrapper .DMPointsSubtitile h5 {
  padding-left: 10px;
  color: #79bd12;
  font-weight: 700;
}
.digitalMarketing-cards {
  background-color: #0e476b;
  margin: 20px 0px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.digitalMarketing-cards p {
  /* color: #676767; */
  color: #fff;
  opacity: 0.9;
}
.sub-digitalMarketing-cards {
  width: 55.55%;
}
.whyDm-img {
  width: 400px;
  height: 250px;
  opacity: 0;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  transform: translateY(-50%) rotate(19deg) scale(0);
  right: -10px;
  z-index: 9;
  transition: all 1s ease-in-out;
}
.digitalMarketing-cards:hover .whyDm-img {
  opacity: 1;
  transform: translateY(-50%) rotate(19deg) scale(1);
}



/* Media Query */

/* Media Queries */
@media (max-width: 576px) {
  .sub-digitalMarketing-cards {
    width: 100%;
  }
  .digitalMarketing-cards{
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .sub-digitalMarketing-cards {
    width: 100%;
  }
}