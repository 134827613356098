.requestCallBack-section {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1.5)),
    url("../Images/bgRequestcallBack.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  padding-top:20px;
}
.requestCallBackLeft {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  box-shadow: 0px 0px 60px 10px rgba(8, 8, 8, 0.2);
  padding: 20px;
  margin: 20px;
}
.requestCallBack h3 {
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.req-inputWrapper {
  padding: 10px;
}
.req-inputWrapper .req-form-control,
.req-inputWrapper .req-form-select {
  background-color: transparent;
  color: #fff;
}
.req-inputWrapper .req-form-select option {
  background-color: transparent;
  color: black;
}
.req-inputWrapper .req-form-control:focus,
.req-inputWrapper .req-form-select:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.req-inputWrapper .req-form-control,
.req-inputWrapper .req-form-select {
  border-radius: 0px !important;
  padding: 10px 20px !important;
  border: 1px solid rgba(231, 214, 214, 0.5);
  border-radius: 0;
  outline: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: #fff;
  width: 100%;
}
.req-inputWrapper .req-form-control::placeholder{
  color: #fff;
  opacity: 0.8;
}
.requestCallBackRight {
  padding: 20px;
}
.courseDescription-top h3,
.courseDescription-top p {
  color: #fff;
}
.req-courseDescription-bottom {
  padding: 20px 0px;
}
.req-courseDescription-bottom .req-lowFees {
  line-height: 2.1em;
  background-color: #0e476d;
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  padding-top: 20px !important;
  padding-right: 30px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}

.req-courseDescription-bottom .req-studyMaterial {
  line-height: 2.1em;
  background-color: #79bd12;
  padding-top: 20px !important;
  padding-right: 30px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}
.req-courseDescription-bottom .req-placement { 
  line-height: 2.1em;
  background-color: #0e476d;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
  padding-top: 20px !important;
  padding-right: 30px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  margin-bottom: 0px !important;
  display: flex;
  
  align-items: center;
}
.req-courseDescription-bottom .req-lowFees p,
.req-courseDescription-bottom .req-studyMaterial p,
.req-courseDescription-bottom .req-placement p {
  padding-left: 10px;
  color: #fff;
  font-size: 18px;
}
.req-checkIcon{
  color: #fff;
}
.req-btn {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
}
.req-errors{
  font-size: 13px;
  color: #dd1717;
  padding-left: 10px;
  margin-bottom: 0px;
}
.courseDescription-top{
  text-transform: capitalize;
}
.courseDescription-top span{
  color: #79bd12;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .courseDescription-top h3{
    font-size: 25px;
  }
  .requestCallBackLeft{
    padding: 0;
    margin: 0;
  }
}