.Gd-service-section {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.gd-banner {
  width: 100%;
  position: relative;
}
.gd-banner img {
  width: 100%;
  height: 70vh;
}

.gd-banner:before,
.gd-banner:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.gd-banner:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.gd-section {
  position: relative;
}

.gd-green {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.gd-purple {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.gd-print-design {
  width: 50%;
  display: flex;
  margin: 0 auto;
  padding: 100px 0 50px 0;
}

.gd-headings h3 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: #0e476b;
  letter-spacing: 0.2px;
}

.gd-headings h3 span {
  color: #dd1717;
}

.gd-headings p {
  font-size: 18px;
  text-align: center;
  padding-top: 40px;
  color: #676c71;
  letter-spacing: 0.2px;
}

.gd-card-section {
  padding: 50px 100px;
}

.gd-card-wrapper {
  padding: 12px;
}

.gd-print-card {
  background: linear-gradient(145deg, #ffffff);
  box-shadow: 2px 2px 10px rgb(0 0 0/5%), 2px 2px 10px rgb(0 0 0/5%);
  padding: 30px;
  border-radius: 10px;
  border: 0.5 solid #d1d9e6;
}

.gd-print-card:hover {
  /* background: linear-gradient(145deg, #5054b9, #c05757); */
  color: #fff;
}

.gd-pc-inner:hover {
  color: #fff !important;
}

.gd-pc-inner {
  display: grid;
  place-items: center;
  align-items: center;
  width: 100%;
}

.gd-pc-inner img {
  width: 70px;
}

.print-card-heading {
  color: #0e476b;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-top: 20px;
}

.print-card-text {
  color: #676c71;
  font-size: 17px;
  text-align: center;
}

.logo-design {
  padding: 50px 100px;
}

.bg-wrapper {
  padding: 50px 0;
}

.brand-guid {
  padding: 50px 0;
}

.brand-img-container {
  position: relative;
}

.brand-img {
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.brand-para {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7490196078431373);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.brand-img-container:hover .brand-img {
  /* opacity: 0.3; */
  /* background-color: #000; */
}

.brand-img-container:hover .brand-para {
  /* opacity: 1; */
}
.brand-img-container:hover .brand-para p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.2px;
}
.brand-para p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.2px;
}

.brand-panel-name {
  /* position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff; */
  background-color: #fff;
  bottom: 10px;
  font-size: 20px;
  position: absolute;
  right: 0;
  text-transform: capitalize;
}
.brand-img-container p {
  color: #0e476b;
  letter-spacing: 0.2px;
  padding: 5px 10px;
  text-align: center;
}

.gd-contact-section {
  background: url("../../Images/Services/home-infotechno-contact-bg.jpg")
    no-repeat center center;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: end;
}

.gd-man {
  width: 400px;
}

.contact-icon {
  color: #0e476b;
}

.gd-contact-right {
  display: flex;
  justify-content: center;
  width: 100%;
}

.gd-contact-wrapper {
  display: grid;
  place-items: center;
}

.gd-contact-right .reach-out-now {
  text-transform: uppercase;
  color: #0e476b;
  padding: 20px 0;
  margin: 0;
}

.gd-contact-mb,
.gd-contact-email {
  color: #0e476b;
  font-weight: 600;
  font-size: 35px;
}

.contact-btn-wrapper {
  padding: 20px 0;
}

.contact-btn {
  background-color: #dd1717;
  padding: 10px 20px;
  border-radius: 3px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

/* media queries */
   
  @media (max-width: 576px) {
    .gd-green,
    .gd-purple {
      display: none;
    }
  
    .gd-print-design {
      width: 100%;
      padding: 50px 20px;
    }
  
    .gd-card-section {
      padding: 50px 20px;
    }
  
    .gd-card-wrapper {
      padding: 15px 0;
    }
  
    .logo-design {
      padding: 50px 20px;
    }
  
    .brand-para {
      padding: 20px;
    }
  
    .brand-para p {
      font-size: 12px;
    }
  
    .gd-man {
      width: 200px;
    }
  
    .gd-contact-man {
      display: flex;
      justify-content: center;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .gd-green,
    .gd-purple {
      display: none;
    }
  
    .gd-print-design {
      width: 100%;
      padding: 50px 20px;
    }
  
    .gd-card-section {
      padding: 50px 20px;
    }
  
    .gd-card-wrapper {
      padding: 15px;
    }
  
    .logo-design {
      padding: 50px 20px;
    }
  
    .brand-para {
      padding: 20px;
    }
  
    .brand-para p {
      font-size: 12px;
    }
  
    .gd-man {
      width: 200px;
    }
  
    .gd-contact-man {
      display: flex;
      justify-content: center;
    }
  }
  @media (min-width: 992px) and (max-width: 1399px) {
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
  } 